import { useState } from 'react'

import PropTypes from 'prop-types'

import FacilityContactForm from './createAssignmentForm/forms/facilityContactForm'
import RoleSelector from './createAssignmentForm/roleSelector'

const FormComponents = {
  FACILITY_CONTACT: FacilityContactForm
}

const CreateAssignmentForm = ({ facilityUuid }) => {
  const [targetRole, setTargetRole] = useState(null)

  const FormComponent = FormComponents[targetRole]

  if (FormComponent === undefined) return <RoleSelector facilityUuid={facilityUuid} onChange={setTargetRole} />

  return (
    <>
      <RoleSelector facilityUuid={facilityUuid} onChange={setTargetRole} />
      <FormComponent facilityUuid={facilityUuid} />
    </>
  )
}

CreateAssignmentForm.propTypes = {
  facilityUuid: PropTypes.string.isRequired
}

export default CreateAssignmentForm
