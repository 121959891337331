import { Col, Row } from 'reactstrap'

import { useQuery } from '@apollo/client'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import Loading from 'components/helper/loading/loading'
import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { GraphQlError } from 'components/ui/genericComponents/errorHandling'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import { getAssignedUsersForFacility } from './assignedUsersList.graphql'

import UserAssignment from './assignedUserList/userAssignment'

const AssignedUsersList = ({ facilityUuid, locale }) => {
  const { loading, error, data, fetchMore, previousData } = useQuery(getAssignedUsersForFacility, {
    variables: { facilityUuid },
    notifyOnNetworkStatusChange: true
  })

  if (loading && !previousData?.facility?.userAssignments?.edges?.length > 0) return <Loading />
  if (error) return <GraphQlError error={error} className="mb-0" />

  const userAssignments = data.facility.userAssignments.edges.map(({ node }) => node)
  const pageInfo = data.facility.userAssignments.pageInfo
  const totalCount = data.facility.userAssignments.totalCount
  const loadMore = () => fetchMore({ variables: { facilityUuid, afterCursor: pageInfo.endCursor } })
  const loadMoreLabel = locale.loadMore({ remaining: totalCount - userAssignments.length })

  return (
    <>
      <Row>
        {userAssignments.map(userAssignment => (
          <Col key={userAssignment.id} sm="12" lg="6" className="my-2 py-0 d-flex">
            <UserAssignment {...userAssignment} />
          </Col>
        ))}
      </Row>
      {pageInfo.hasNextPage && (
        <Row>
          <Col sm={12} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }}>
            <ProcessingButton
              processing={loading}
              label={loadMoreLabel}
              processing_label={loadMoreLabel}
              icon={faAngleDown}
              onClick={loadMore}
              block
            />
          </Col>
        </Row>
      )}
    </>
  )
}

AssignedUsersList.propTypes = {
  facilityUuid: PropTypes.string.isRequired
}

export default withLocale(AssignedUsersList, { key: 'facility.userAssignments' })
