import { useRef } from 'react'

import { Button, UncontrolledTooltip } from 'reactstrap'

import { useQuery } from '@apollo/client'
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ErrorIconWithTooltip } from 'components/ui/genericComponents/errorHandling'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import { getChangeInvoiceRecipientPermissionForFacility } from './invoiceRecipient.graphql'

const EditButton = ({ onClick, facilityUuid, locale }) => {
  const buttonRef = useRef()

  const { data, loading, error } = useQuery(getChangeInvoiceRecipientPermissionForFacility, {
    variables: { facilityUuid }
  })

  if (loading) return <FontAwesomeIcon icon={faSpinner} spin />
  if (error) {
    const errorMessage = `Failed to check if permitted to edit: ${error.message}`
    // NOTE: icon should look like the button that would be in its place
    return <ErrorIconWithTooltip errorMessage={errorMessage} className="btn-transparent btn-icon-only btn-link" />
  }

  // don't render the button if not permitted to edit
  if (!data.facility.permissions.changeInvoiceRecipient) return null

  return (
    <>
      <Button aria-label={locale.edit} className="btn-icon-only" color="link" onClick={onClick} innerRef={buttonRef}>
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      <UncontrolledTooltip delay={500} placement="top" target={buttonRef}>
        {locale.edit}
      </UncontrolledTooltip>
    </>
  )
}

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  facilityUuid: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    edit: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(EditButton)
