import { Table } from 'reactstrap'

import { useQuery } from '@apollo/client'

import Loading from 'components/helper/loading/loading'
import { GraphQlError } from 'components/ui/genericComponents/errorHandling'
import { EmailLink, PhoneLink } from 'components/ui/genericComponents/links'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import { getInvoiceRecipientInformationForFacility } from './invoiceRecipient.graphql'

import { TableField } from '../../partials'

const DataTable = ({ facilityUuid, locale }) => {
  const { data, loading, error } = useQuery(getInvoiceRecipientInformationForFacility, { variables: { facilityUuid } })

  if (loading) return <Loading />
  if (error) return <GraphQlError error={error} />

  const invoiceRecipient = data.facility.invoiceRecipient

  return (
    <Table striped responsive bordered>
      <tbody>
        <TableField label={locale.recipientName} value={invoiceRecipient.recipientName} />
        <TableField label={locale.email} value={<EmailLink email={invoiceRecipient.email} />} />
        <TableField label={locale.phoneNumber} value={<PhoneLink phone={invoiceRecipient.phoneNumber} />} />
        <TableField label={locale.address.street} value={invoiceRecipient.address.street} />
        <TableField label={locale.address.street_addition} value={invoiceRecipient.address.street_addition} />
        <TableField label={locale.address.zipcode} value={invoiceRecipient.address.zipcode} />
        <TableField label={locale.address.city} value={invoiceRecipient.address.city} />
        <TableField label={locale.address.country} value={invoiceRecipient.address.country?.label} />
        <TableField label={locale.address.state} value={invoiceRecipient.address.state?.label} />
      </tbody>
    </Table>
  )
}

DataTable.propTypes = {
  facilityUuid: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    recipientName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      street_addition: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default withLocale(DataTable, { key: 'facility.invoiceRecipient' })
