import { Children, cloneElement, useId } from 'react'

import { Col, FormFeedback, Label } from 'reactstrap'

import PropTypes from 'prop-types'

import { FormGroupContainer } from '../../../partials'

const FormRow = ({ label, error, children }) => {
  const inputId = useId()

  // NOTE: ensures we have only one child and injects the id into it. This
  // allows us to be somewhat flexible with the type of input (e.g. text vs
  // select) while not having to define a lot of ids in the parent component.
  const inputElement = Children.only(children)
  const inputElementWithInjectedId = cloneElement(inputElement, { id: inputId })

  return (
    <FormGroupContainer>
      <Col xs="12" md="5" className="text-md-right">
        <Label for={inputId} className="control-label">
          {label}
        </Label>
      </Col>
      <Col xs="12" md="7">
        {inputElementWithInjectedId}
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </FormGroupContainer>
  )
}

FormRow.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default FormRow
