import { Component, Fragment } from 'react'

import { NavLink as RouterNavLink } from 'react-router-dom'
// ReactStrap Components
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarToggler,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap'

import { faAngleRight, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TokenAuth } from '../../../helpers/token-auth'
import db from '../../../indexed-db/db.js'
// additional components
import Logo from '../logo/logo.jsx'
import FaqButton from '../nav-menu/faq-button.jsx'

// CSS
import './_nav-menu.scss'

const Link = props => <RouterNavLink {...props} />

class NavMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      offline_facilities_available: false
    }
    window.onscroll = function () {
      if (window.pageYOffset < 300) {
        document.getElementById('header').style.top = '0'
      } else {
        document.getElementById('header').style.top = '-164px'
      }
    }
  }

  componentDidUpdate = (_prevProps, prevState) => {
    db.keys('Facilities')
      .then(keys => {
        let offline_facilities_available = keys.length > 0
        if (!prevState || prevState.offline_facilities_available != offline_facilities_available) {
          this.setState({ offline_facilities_available })
        }
      })
      .catch(error => {
        if (error.name === 'InvalidStateError') {
          console.error('Could not access indexedDB:', error)
        } else {
          throw error
        }
      })
  }

  componentDidMount = () => {
    document.addEventListener('click', this.close, true)
    this.componentDidUpdate()
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.close, true)
  }

  toggle = () =>
    this.setState({
      isOpen: !this.state.isOpen
    })

  close = e => {
    if (this.state.isOpen && !e.target.closest('.dropdown-toggle,.navbar-toggler')) {
      this.setState({
        isOpen: false
      })
    }
  }

  full_name = () => {
    return this.props.userData ? this.props.userData.first_name + ' ' + this.props.userData.last_name : ''
  }
  email = () => {
    return this.props.userData ? this.props.userData.email : ''
  }
  permissions = () => {
    return this.props.userData.permissions
  }

  isDeveloper = () => {
    return this.email().endsWith('@webit.de')
  }

  isAdmin = () => {
    const permissions = this.props.userData.permissions
    return (
      permissions.administrate_access_control ||
      permissions.administrate_regions ||
      permissions.administrate_licensee_owners ||
      permissions.administrate_all_users ||
      permissions.administrate_all_collectors
    )
  }

  render() {
    const { online } = this.props
    const { offline_facilities_available } = this.state

    return (
      <div className="nav-menu">
        <Navbar light role="navigation">
          <div className="container">
            <Logo />
            <div className="nav-wrapper">
              {TokenAuth.isLoggedIn() ? (
                <Fragment>
                  <FaqButton faqLabel={this.props.locale.manuals} />
                  <NavbarToggler onClick={this.toggle} />
                  <Collapse isOpen={this.state.isOpen} navbar onClick={this.close}>
                    <Nav navbar>
                      <NavItem>
                        <NavLink tag={Link} to="/facilities">
                          {this.props.locale.facilities}
                        </NavLink>
                      </NavItem>
                      {online && (
                        <Fragment>
                          <NavItem>
                            <NavLink tag={Link} to="/online-training">
                              {this.props.locale.online_training.header}
                            </NavLink>
                          </NavItem>

                          {this.permissions().construction_kit && online ? (
                            <UncontrolledDropdown nav inNavbar>
                              <DropdownToggle nav caret>
                                {this.props.locale.form_construction_kit}
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem tag={Link} to="/form-kit/versions">
                                  <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                  {this.props.locale.versions}
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/form-kit/sections/version/">
                                  <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                  {}
                                  {this.props.locale.default}-{this.props.locale.sections}
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/form-kit/forms">
                                  <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                  {this.props.locale.form.plural}
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/form-kit/facility-kinds">
                                  <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                  {this.props.locale.offer_types}
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/form-kit/short-report-variables">
                                  <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                  {this.props.locale.short_report.variable.plural}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          ) : (
                            ''
                          )}
                          {this.isAdmin() && online ? (
                            <UncontrolledDropdown nav inNavbar>
                              <DropdownToggle nav caret>
                                {this.props.locale.administration}
                              </DropdownToggle>
                              <DropdownMenu right>
                                {this.permissions().administrate_regions ? (
                                  <DropdownItem tag={Link} to="/admin/regions">
                                    <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                    {this.props.locale.region.plural}
                                  </DropdownItem>
                                ) : (
                                  ''
                                )}
                                {this.permissions().administrate_regions ? (
                                  <DropdownItem tag={Link} to="/admin/widget-filters">
                                    <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                    {this.props.locale.widget_filter.plural}
                                  </DropdownItem>
                                ) : (
                                  ''
                                )}
                                {this.permissions().change_providers ? (
                                  <DropdownItem tag={Link} to="/admin/providers">
                                    <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                    {this.props.locale.provider.plural}
                                  </DropdownItem>
                                ) : (
                                  ''
                                )}
                                {this.permissions().administrate_licensee_owners ? (
                                  <DropdownItem tag={Link} to="/admin/license-owners">
                                    <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                    {this.props.locale.license_owner.singular}
                                  </DropdownItem>
                                ) : (
                                  ''
                                )}
                                {this.permissions().change_facility_asset_downloads ? (
                                  <DropdownItem tag={Link} to="/admin/asset-downloads">
                                    <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                    {this.props.locale.asset_download.plural}
                                  </DropdownItem>
                                ) : (
                                  ''
                                )}
                                {this.permissions().administrate_access_control ? (
                                  <Fragment>
                                    <DropdownItem tag={Link} to="/admin/roles">
                                      <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                      {this.props.locale.settings.plural}
                                    </DropdownItem>
                                  </Fragment>
                                ) : (
                                  ''
                                )}
                                {this.permissions().administrate_all_users ? (
                                  <DropdownItem tag={Link} to="/admin/users">
                                    <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                    {this.props.locale.user_management}
                                  </DropdownItem>
                                ) : (
                                  ''
                                )}
                                {this.permissions().administrate_partner_gateway_users ? (
                                  <DropdownItem tag={Link} to="/admin/partner_gateway_users">
                                    <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                    {this.props.locale.partner_gateway_user.plural}
                                  </DropdownItem>
                                ) : (
                                  ''
                                )}
                                {this.permissions().administrate_input_api_users ? (
                                  <DropdownItem tag={Link} to="/admin/input_api_users">
                                    <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                    {this.props.locale.input_api_user.plural}
                                  </DropdownItem>
                                ) : (
                                  ''
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          ) : (
                            ''
                          )}

                          {this.isDeveloper() && (
                            <NavItem>
                              <NavLink tag={Link} to="/debug/graphiql">
                                GraphiQl
                              </NavLink>
                            </NavItem>
                          )}

                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                              <FontAwesomeIcon icon={faUser} />
                            </DropdownToggle>
                            <DropdownMenu right>
                              {online && (
                                <DropdownItem tag={Link} to="/user/settings">
                                  <FontAwesomeIcon className="icon-prepend" icon={faAngleRight} />
                                  {this.props.locale.settings.plural}
                                </DropdownItem>
                              )}
                              <DropdownItem
                                tag={Link}
                                to="/logout"
                                disabled={offline_facilities_available}
                                id="logout-link"
                              >
                                <FontAwesomeIcon className="icon-prepend" icon={faSignOutAlt} />
                                {this.props.locale.logout}
                              </DropdownItem>
                              {offline_facilities_available && (
                                <UncontrolledTooltip placement="bottom" target="logout-link">
                                  Da Sie einen Betrieb für die Offline-Bearbeitung heruntergeladen haben, können Sie
                                  sich derzeit nicht ausloggen. Sobald Sie den Offline verfügbaren Betrieb
                                  synchronisiert oder gelöscht haben, ist das Ausloggen wieder möglich.
                                </UncontrolledTooltip>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Fragment>
                      )}
                    </Nav>
                  </Collapse>
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </Navbar>
      </div>
    )
  }
}

export default NavMenu
