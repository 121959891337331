import { Col, Row } from 'reactstrap'

import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import AssignedUsersList from './assignedUsersList'
import CreateAssignment from './createAssignment'

const AssignedUsers = ({ facilityUuid, locale }) => (
  <>
    <Row>
      <Col sm={12} lg={6} className="my-1 py-0">
        <h3>{locale.heading}</h3>
      </Col>
      <Col sm={12} lg={6} className="my-1 py-0">
        <div className="float-lg-right">
          <CreateAssignment facilityUuid={facilityUuid} />
        </div>
      </Col>
    </Row>
    <AssignedUsersList facilityUuid={facilityUuid} />
  </>
)

AssignedUsers.propTypes = {
  facilityUuid: PropTypes.string.isRequired
}

export default withLocale(AssignedUsers, { key: 'facility.userAssignments' })
