import { useId } from 'react'

import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'

import PropTypes from 'prop-types'

const StringInput = ({ label, onChange, error }) => {
  const inputId = useId()

  return (
    <FormGroup row className="my-3">
      <Label for={inputId} sm={2}>
        {label}
      </Label>
      <Col sm={10}>
        <Input id={inputId} onChange={({ target: { value } }) => onChange(value)} invalid={!!error} />
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </FormGroup>
  )
}

StringInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default StringInput
