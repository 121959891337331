import React, { Fragment } from 'react'

import { Col, Collapse, Row, Table } from 'reactstrap'

import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CreateFacilityProvider, EditFacilityProvider, UpdateFacilityProvider } from '.'

class Provider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ collapse: !this.state.collapse })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'Closed' })

  allUsersAttached = () =>
    !!this.props.facility.collector && !!this.props.facility.dsft_user && !!this.props.facility.license_owner_user

  render() {
    const {
      toggle,
      onEntering,
      onExiting,
      props: { uuid, permissions, locale, facility, match },
      state: { status, collapse }
    } = this

    return (
      // TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
      <div className="collapse-container">
        <div className={`collapse-header btn d-flex align-items-center flex-row clearfix ${status}`} onClick={toggle}>
          <h2 className="h4 collapse-headline">
            <span className="collapse-headline-text">{locale.provider.singular}</span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h2>
        </div>
        <Collapse className="collapse-content" isOpen={collapse} onEntering={onEntering} onExiting={onExiting}>
          <Row>
            <Col size={{ sm: 12 }}>
              <Table striped bordered responsive>
                <thead className="thead-light">
                  <tr>
                    <th>
                      {locale.provider.singular}
                      <div className="fa-pull-right">
                        {facility.permissions.change_provider && (
                          <UpdateFacilityProvider
                            {...{
                              uuid: facility.uuid,
                              providers: facility.possible_providers,
                              currentProvider: facility.provider
                            }}
                          />
                        )}
                        {facility.permissions.update_provider && facility.provider && (
                          <EditFacilityProvider facility={facility} />
                        )}
                        {facility.permissions.create_provider && !facility.provider && (
                          <CreateFacilityProvider facility={facility} />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Row>
                        <Col md="6">
                          {facility.provider ? (
                            <Fragment>
                              <h5>{facility.provider.name}</h5>
                              <InformationLine>{facility.provider.email}</InformationLine>
                              <InformationLine>{facility.provider.phone}</InformationLine>
                              <InformationLine>{facility.provider.address?.street}</InformationLine>
                              <InformationLine>{facility.provider.address?.street_addition}</InformationLine>
                              <InformationLine>
                                {facility.provider.address?.zipcode ? `${facility.provider.address?.zipcode}` : ''}
                                {facility.provider.address?.zipcode && facility.provider.address?.city && ' '}
                                {facility.provider.address?.city}
                              </InformationLine>
                              <InformationLine>{facility.provider.address?.state?.label}</InformationLine>
                              <InformationLine>{facility.provider.address?.country?.label}</InformationLine>
                            </Fragment>
                          ) : (
                            locale.facility.provider.none
                          )}
                        </Col>
                        {facility?.provider?.miscellaneous ? (
                          <Col md="6">
                            <strong className="text-primary-dark">{locale.provider.miscellaneous}</strong>
                            <p>{facility.provider.miscellaneous}</p>
                          </Col>
                        ) : null}
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Collapse>
      </div>
    )
  }
}

const InformationLine = ({ children }) => (children?.length ? <span className="d-block">{children}</span> : null)

export default Provider
