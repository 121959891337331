import { Col, Row } from 'reactstrap'

import PropTypes from 'prop-types'

import AssignExistingUser from './facilityContactForm/assignExistingUser'
import CreateNewUser from './facilityContactForm/createNewUser'

const FacilityContactForm = ({ facilityUuid }) => {
  return (
    <Row>
      <Col xs={12} lg={6}>
        <AssignExistingUser facilityUuid={facilityUuid} />
      </Col>
      <Col xs={12} lg={6}>
        <CreateNewUser facilityUuid={facilityUuid} />
      </Col>
    </Row>
  )
}

FacilityContactForm.propTypes = {
  facilityUuid: PropTypes.string.isRequired
}

export default FacilityContactForm
