import React from 'react'

import { Button, Collapse, Table, Tooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TableField } from '../partials'

class ThirdPartyIds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const {
      props: { facility, locale, editContentRight },
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      state: { status, isOpen, tooltip_open }
    } = this

    return (
      <React.Fragment>
        {/* TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx` */}
        <div className="collapse-container">
          <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
            <h3 className="collapse-headline">
              <span className="collapse-headline-text">{locale.third_party_ids.plural}</span>
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
            </h3>
            {facility.permissions.edit_third_party_ids && (
              <div className="list-actions">
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`third_party_ids`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip
                  delay={500}
                  placement="top"
                  isOpen={tooltip_open}
                  target={`third_party_ids`}
                  toggle={toggleTooltip}
                >
                  {locale.edit}
                </Tooltip>
              </div>
            )}
          </div>
          <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
            {facility.third_party_ids.length > 0 ? (
              <Table striped responsive bordered>
                <tbody>
                  {facility.third_party_ids.map(third_party_id => (
                    <TableField
                      key={third_party_id.id}
                      label={
                        facility.possible_third_party_id_types.filter(type => type.key === third_party_id.key)[0].name
                          .de
                      }
                      value={third_party_id.value}
                    />
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>{locale.third_party_ids.empty}</p>
            )}
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default ThirdPartyIds
