import { useQuery } from '@apollo/client'

import { Select } from 'components/modules/selects/react-select'
import PropTypes from 'prop-types'

import { GraphQlError } from '../genericComponents/errorHandling'

// NOTE: currently does not support fetching a paged result, because it wasn't
// needed. If this component should be used to fetch a huge dataset, please add
// support for paged results, preferably with relay-style graphQl
// connections (https://graphql-ruby.org/pagination/connection_concepts.html).
// You can use `withAsyncPaginate`, like already implemented in
// /home/kramer/projects/gitterlabor.webit.de/webit/DSFT/dsft-barrierefrei_db/services/spa/src/components/views/facility/information/types/bundle_information/form/add_bundled_facilities.jsx.
const AsyncSelect = ({
  query,
  queryOptions = {},
  dataToOptionsMapper,
  selectedOptionValues = [],
  conditionalRenderer,
  ...props
}) => {
  const { loading, error, data } = useQuery(query, queryOptions)

  if (error) return <GraphQlError error={error} className="mb-0" />

  const defaultProps = {
    isLoading: loading,
    menuPortalTarget: document.body,
    useReactWindowMenuList: true,
    ...props
  }

  if (loading) return <Select {...defaultProps} />

  const options = dataToOptionsMapper(data)
  // NOTE: little helper to allow passing only selected value attributes and finding the corresponding options.
  // gets only used, if no `value` prop is passed to the component.
  const selectedOptions = options.filter(option => selectedOptionValues.includes(option.value))

  const defaultRender = <Select options={options} value={selectedOptions} {...defaultProps} />

  if (conditionalRenderer) {
    return conditionalRenderer({ defaultRender, data, options, Component: Select, defaultProps })
  }

  return defaultRender
}

AsyncSelect.propTypes = {
  query: PropTypes.object.isRequired,
  queryOptions: PropTypes.object,
  // NOTE: this function takes the data object returned from the graphql query
  // and should return an array of options in this format:
  //
  // ```js
  // { label: 'Option Label', value: 'Option Value' }
  // ```
  //
  // The options are passed through to react select, so you can do anything with
  // them, the library accepts: https://react-select.com/home#getting-started
  dataToOptionsMapper: PropTypes.func.isRequired,
  // This should be an array of the `value` attributes of all selected options.
  // NOTE: This is used, because the implementing component probably doesn't know
  // about specific options and their labels, etc. Instead, only specific values
  // are known and can be passed via this prop.
  selectedOptionValues: PropTypes.array,
  conditionalRenderer: PropTypes.func
  // NOTE: you might pass any other prop accepted by the underlying
  // Select-Component. Most of them are props for ReactSelect, see
  // https://react-select.com/home
}

export default AsyncSelect
