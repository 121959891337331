import { useEffect, useState } from 'react'

import { Form, Input } from 'reactstrap'

import { useMutation, useQuery } from '@apollo/client'

import Loading from 'components/helper/loading/loading'
import { GraphQlError } from 'components/ui/genericComponents/errorHandling'
import { AsyncSelect } from 'components/ui/select'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import {
  changeInvoiceRecipient as changeInvoiceRecipientMutation,
  getCountriesForInvoiceRecipient,
  getInvoiceRecipientInformationForFacility,
  getStatesForInvoiceRecipient
} from './invoiceRecipient.graphql'

import Actions from './updateForm/actions'
import FormRow from './updateForm/formRow'

const countryDataToOptionsMapper = data => data.possible_countries.map(({ key, label }) => ({ value: key, label }))
const stateDataToOptionsMapper = data => data.possible_states?.map(({ key, label }) => ({ value: key, label })) || [] // NOTE: fallback to empty array if there are no states (e.g. no country selected)

const UpdateForm = ({ facilityUuid, closeForm, locale }) => {
  // fetch initial data
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(getInvoiceRecipientInformationForFacility, { variables: { facilityUuid } })

  // init state with empty values
  const [invoiceRecipient, setInvoiceRecipient] = useState({
    recipientName: '',
    email: '',
    phoneNumber: '',
    address: {
      street: '',
      street_addition: '',
      zipcode: '',
      city: '',
      country_code: '',
      state: ''
    }
  })

  useEffect(() => {
    if (queryData) {
      // update state as soon as data is available
      setInvoiceRecipient({
        recipientName: queryData.facility.invoiceRecipient.recipientName,
        email: queryData.facility.invoiceRecipient.email,
        phoneNumber: queryData.facility.invoiceRecipient.phoneNumber,
        address: {
          street: queryData.facility.invoiceRecipient.address.street,
          street_addition: queryData.facility.invoiceRecipient.address.street_addition,
          zipcode: queryData.facility.invoiceRecipient.address.zipcode,
          city: queryData.facility.invoiceRecipient.address.city,
          country_code: queryData.facility.invoiceRecipient.address.country?.key,
          state: queryData.facility.invoiceRecipient.address.state?.key
        }
      })
    }
  }, [queryData])

  const changeRecipientName = e => setInvoiceRecipient({ ...invoiceRecipient, recipientName: e.target.value })
  const changeEmail = e => setInvoiceRecipient({ ...invoiceRecipient, email: e.target.value })
  const changePhoneNumber = e => setInvoiceRecipient({ ...invoiceRecipient, phoneNumber: e.target.value })
  const changeStreet = e =>
    setInvoiceRecipient({ ...invoiceRecipient, address: { ...invoiceRecipient.address, street: e.target.value } })
  const changeStreetAddition = e =>
    setInvoiceRecipient({
      ...invoiceRecipient,
      address: { ...invoiceRecipient.address, street_addition: e.target.value }
    })
  const changeZipcode = e =>
    setInvoiceRecipient({ ...invoiceRecipient, address: { ...invoiceRecipient.address, zipcode: e.target.value } })
  const changeCity = e =>
    setInvoiceRecipient({ ...invoiceRecipient, address: { ...invoiceRecipient.address, city: e.target.value } })
  const changeCountry = selectedOption =>
    setInvoiceRecipient({
      ...invoiceRecipient,
      address: { ...invoiceRecipient.address, country_code: selectedOption?.value, state: null }
    })
  const changeState = selectedOption =>
    setInvoiceRecipient({ ...invoiceRecipient, address: { ...invoiceRecipient.address, state: selectedOption?.value } })

  // init mutation
  const [changeInvoiceRecipient, { loading: mutationLoading, error: mutationError }] = useMutation(
    changeInvoiceRecipientMutation,
    {
      onError: _ => {},
      refetchQueries: [{ query: getInvoiceRecipientInformationForFacility, variables: { facilityUuid } }],
      awaitRefetchQueries: true,
      onCompleted: _ => {
        closeForm()
      }
    }
  )

  const submit = event => {
    event.preventDefault()
    event.stopPropagation()

    changeInvoiceRecipient({
      variables: {
        input: {
          facilityUuid,
          invoiceRecipient
        }
      }
    })
  }

  if (queryLoading) return <Loading />
  if (queryError || mutationError) return <GraphQlError error={queryError || mutationError} />

  return (
    <div className="box">
      <Form onSubmit={submit}>
        <FormRow label={locale.recipientName}>
          <Input value={invoiceRecipient.recipientName} onChange={changeRecipientName} />
        </FormRow>
        <FormRow label={locale.email}>
          <Input value={invoiceRecipient.email} onChange={changeEmail} />
        </FormRow>
        <FormRow label={locale.phoneNumber}>
          <Input value={invoiceRecipient.phoneNumber} onChange={changePhoneNumber} />
        </FormRow>
        <FormRow label={locale.address.street}>
          <Input value={invoiceRecipient.address.street} onChange={changeStreet} />
        </FormRow>
        <FormRow label={locale.address.street_addition}>
          <Input value={invoiceRecipient.address.street_addition} onChange={changeStreetAddition} />
        </FormRow>
        <FormRow label={locale.address.zipcode}>
          <Input value={invoiceRecipient.address.zipcode} onChange={changeZipcode} />
        </FormRow>
        <FormRow label={locale.address.city}>
          <Input value={invoiceRecipient.address.city} onChange={changeCity} />
        </FormRow>
        <FormRow label={locale.address.country}>
          <AsyncSelect
            query={getCountriesForInvoiceRecipient}
            dataToOptionsMapper={countryDataToOptionsMapper}
            selectedOptionValues={[invoiceRecipient.address.country_code]}
            onChange={changeCountry}
            isClearable
          />
        </FormRow>
        <FormRow label={locale.address.state}>
          <AsyncSelect
            query={getStatesForInvoiceRecipient}
            isDisabled={!invoiceRecipient.address.country_code}
            queryOptions={{ variables: { countryCode: invoiceRecipient.address.country_code || '' } }}
            dataToOptionsMapper={stateDataToOptionsMapper}
            selectedOptionValues={[invoiceRecipient.address.state]}
            onChange={changeState}
            isClearable
          />
        </FormRow>
        <Actions closeForm={closeForm} currentlySaving={mutationLoading} />
      </Form>
    </div>
  )
}

UpdateForm.propTypes = {
  facilityUuid: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    recipientName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      street_addition: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default withLocale(UpdateForm, { key: 'facility.invoiceRecipient' })
