import { useReducer } from 'react'

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import { DispatchContext, actionIds, initialState, reducer } from './createAssignmentModalContext'

// TODO: maybe this could be abstracted into a generic UI component, taking children, title, submit-action, cancel-action, etc.?
const CreateAssignmentModal = ({ children, locale, globalLocale }) => {
  const [{ modalOpen, modalClosable }, dispatch] = useReducer(reducer, initialState)

  const openModal = () => dispatch({ type: actionIds.OPEN_MODAL })
  const closeModal = () => dispatch({ type: actionIds.CLOSE_MODAL })
  const toggleModal = () => dispatch({ type: actionIds.TOGGLE_MODAL })

  return (
    <>
      <Button color="primary" className="px-3" onClick={openModal}>
        <FontAwesomeIcon icon={faLink} className="mr-2" />
        {locale.createAssignment.heading}
      </Button>
      <Modal isOpen={modalOpen} toggle={modalClosable ? toggleModal : undefined} centered size="lg" trapFocus>
        <ModalHeader toggle={modalClosable ? closeModal : undefined}>{locale.createAssignment.heading}</ModalHeader>
        <ModalBody>
          <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={closeModal} disabled={!modalClosable}>
            {globalLocale.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

CreateAssignmentModal.propTypes = {
  children: PropTypes.node,
  globalLocale: PropTypes.shape({
    cancel: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(withLocale(CreateAssignmentModal, { key: 'facility.userAssignments' }), {
  attributeName: 'globalLocale'
})
