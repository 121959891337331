import { Alert } from 'reactstrap'

import objectHash from 'object-hash'
import PropTypes from 'prop-types'

import mapGraphQlErrorResultToMessages from './graphQlError/mapGraphQlErrorResultToMessages'

// This component transforms the returned error object of an apollo hook to a list of Alert components with generic messages
const GraphQlError = ({ error, ...alertProps }) =>
  mapGraphQlErrorResultToMessages(error).map(message => (
    <Alert key={objectHash(message)} color="danger" {...alertProps}>
      {message}
    </Alert>
  ))

GraphQlError.propTypes = {
  // NOTE: not required, might be undefined if there is no error.
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.node, // NOTE: generic error, e.g. a string
    // NOTE: shape of the error object, if the API returned an error object
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.node,
        locations: PropTypes.shape({
          line: PropTypes.number,
          column: PropTypes.number
        }),
        path: PropTypes.array
      })
    )
  ]),
  component: PropTypes.elementType
}

export default GraphQlError
