import { Table } from 'reactstrap'

import PropTypes from 'prop-types'

const TitledBoxWithActions = ({ children, title, actionItems }) => (
  // NOTE: probably shouldn't be a table, as we're not really displaying tabular data, but thats the markup and styling currently used.
  // TODO: this might be refactored later to be built with divs or something
  <Table striped bordered className="align-self-stretch">
    <thead className="thead-light">
      <tr>
        <th>
          {title}
          {actionItems && <div className="fa-pull-right">{actionItems}</div>}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="align-top">{children}</td>
      </tr>
    </tbody>
  </Table>
)

TitledBoxWithActions.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
  actionItems: PropTypes.node
}

export default TitledBoxWithActions
