import React, { useState } from 'react'

import { Badge, Button, Collapse, Row, UncontrolledTooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FacilityCollection from './bundle_information/facility_collection'

const BundleInformation = ({ facility, locale, editContentRight, offlineMode }) => {
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState('closed')

  const toggle = () => setOpen(!open)
  const onEntering = () => setStatus('opened')
  const onExiting = () => setStatus('closed')

  return (
    <React.Fragment>
      {/* TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx` */}
      <div className="collapse-container">
        <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
          <h3 className="collapse-headline">
            <span className="collapse-headline-text">{locale.facility.bundleInformation.label}</span>
            {offlineMode ? (
              <Badge className="ml-3">{locale.facility.bundleInformation.offline}</Badge>
            ) : (
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
            )}
          </h3>
          {facility?.permissions?.updateBundles && !offlineMode && (
            <div className="list-actions">
              <Button
                className="btn-transparent btn-icon-only"
                color="link"
                onClick={editContentRight}
                id="bundle_information"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <UncontrolledTooltip delay={500} placement="top" target="bundle_information">
                {locale.edit}
              </UncontrolledTooltip>
            </div>
          )}
        </div>
        {!offlineMode && (
          <Collapse className="collapse-content" isOpen={open} onEntering={onEntering} onExiting={onExiting}>
            <Row>
              <FacilityCollection
                type="bundledBy"
                uuid={facility.uuid}
                sm="12"
                lg="6"
                className="mt-0 pt-0 mb-sm-3 mb-lg-0"
              />
              <FacilityCollection
                type="bundledFacilities"
                uuid={facility.uuid}
                sm="12"
                lg="6"
                className="mt-0 pt-0 mb-sm-3 mb-lg-0"
              />
            </Row>
          </Collapse>
        )}
      </div>
    </React.Fragment>
  )
}

export default BundleInformation
