import { Button } from 'reactstrap'

import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

const FaqButton = ({ faqLabel }) => {
  return (
    <Button
      outline
      as="a"
      href="https://btg-service.de/handbuch-reisen-fuer-alle"
      className="px-2 px-sm-3 px-md-5 mx-3"
      color="primary"
      target="_blank"
    >
      {faqLabel}
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2" />
    </Button>
  )
}

FaqButton.propTypes = {
  faqLabel: PropTypes.string
}

export default FaqButton
