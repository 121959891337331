import { useQuery } from '@apollo/client'

import Loading from 'components/helper/loading/loading'
import { GraphQlError } from 'components/ui/genericComponents/errorHandling'
import PropTypes from 'prop-types'

import { getUserRolesAllowedToAssignToFacility } from './createAssignment/createAssignment.graphql'

import CreateAssignmentForm from './createAssignment/createAssignmentForm'
import CreateAssignmentModal from './createAssignment/createAssignmentModal'

const CreateAssignment = ({ facilityUuid }) => {
  const { data, loading, error } = useQuery(getUserRolesAllowedToAssignToFacility, {
    variables: { facilityUuid }
  })

  if (loading) return <Loading inline loadingText=" " size="2x" />
  if (error) return <GraphQlError error={error} className="mb-0" />
  if (data.facility.permissions.userRolesAllowedToAssign.length === 0) return null // if the use cannot add any role, we don't have to show the button

  return (
    <CreateAssignmentModal>
      <CreateAssignmentForm facilityUuid={facilityUuid} />
    </CreateAssignmentModal>
  )
}

CreateAssignment.propTypes = {
  facilityUuid: PropTypes.string.isRequired
}

export default CreateAssignment
