import React from 'react'

import { Button, Collapse, Table, Tooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GoogleMap from '../../../../modules/google-map/google-map.jsx'
import { TableField } from '../partials'

class Address extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const {
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      state: { status, isOpen, tooltip_open },
      props: {
        facility: { base_data, permissions },
        locale,
        editContentRight
      }
    } = this

    const marker_info = {
      name_de: base_data?.name_de || '',
      name_en: base_data?.name_en || '',
      street: base_data?.address?.street || '',
      street_addition: base_data?.address?.street_addition || '',
      city: base_data?.address?.city || '',
      zipcode: base_data?.address?.zipcode || ''
    }

    return (
      <React.Fragment>
        {/* TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx` */}
        <div className="collapse-container">
          <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
            <h3 className="collapse-headline">
              <span className="collapse-headline-text">{locale['address']}</span>
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
            </h3>
            {permissions?.update_base_data ? (
              <div className="list-actions">
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`address`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip delay={500} placement="top" isOpen={tooltip_open} target={`address`} toggle={toggleTooltip}>
                  {locale.edit}
                </Tooltip>
              </div>
            ) : null}
          </div>
          <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
            <Table striped responsive bordered>
              <tbody>
                <TableField label={`Straße`} value={base_data?.address?.street || ''} />
                <TableField label={`Straße (Zusatz)`} value={base_data?.address?.street_addition || ''} />
                <TableField label={`Postleitzahl`} value={base_data?.address?.zipcode || ''} />
                <TableField label={`Ort`} value={base_data?.address?.city || ''} />
                <TableField
                  label={`Region`}
                  value={base_data?.regions?.map(region => region.name_de).join(', ') || ''}
                />
                <TableField
                  label={`Widget Filter`}
                  value={base_data?.widget_filters?.map(widget_filter => widget_filter.name_de).join(', ') || ''}
                />
                <TableField label={`Land`} value={base_data?.address?.country?.label || ''} />
                <TableField label={`Bundesland`} value={base_data?.address?.state?.label || ''} />
                <TableField label={`Breitengrad`} value={base_data?.address?.lat || ''} />
                <TableField label={`Längengrad`} value={base_data?.address?.lon || ''} />
              </tbody>
            </Table>
            {base_data?.address?.lat && base_data?.address?.lon && isOpen ? (
              <GoogleMap lat={base_data?.address?.lat} lon={base_data?.address?.lon} marker_info={marker_info} />
            ) : null}
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default Address
