import Update from './update'

const AuditOrderNumber = props => (
  // TODO: container could be refactored to
  // `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
  // Probably with some adjustments or a new element, because it should only
  // LOOK like the header but has no collapsible content.
  <div className="align-items-center box box-small d-flex flex-row mt-0 py-4 collapse-container">
    <h2 className="h4 m-0 text-secondary">Prüfauftragsnummer</h2>
    <div className="align-items-center d-flex ml-auto mb-0 text-right">
      <span
        className={`text-primary-dark font-weight-normal p-3 badge text-right ${
          props.facility?.certification_data?.audit_order_number ? 'badge-success' : 'badge-warning'
        }`}
      >
        <span>{props.facility?.certification_data?.audit_order_number || 'Keine Prüfauftragsnummer vergeben'}</span>
      </span>
      {props.facility?.permissions?.change_certificate && (
        <div className="list-actions ml-4 pl-2 border-left border-secondary">
          <Update {...props} />
        </div>
      )}
    </div>
  </div>
)

export default AuditOrderNumber
