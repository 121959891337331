import { useId } from 'react'

import { Label } from 'reactstrap'

import { AsyncSelect } from 'components/ui/select'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import { getUserRolesAllowedToAssignToFacility } from '../createAssignment.graphql'

const RoleSelector = ({ facilityUuid, onChange, locale }) => {
  const selectId = useId()

  const setRoleValue = selectedOption => {
    onChange(selectedOption?.value)
  }

  const dataToOptionsMapper = data =>
    data.facility.permissions.userRolesAllowedToAssign.map(role => ({
      value: role,
      label: locale.roles[role]
    }))

  const conditionalRenderer = ({ defaultRender, data, options, Component, defaultProps }) => {
    // NOTE: this is easier, than passing the chosen role from the parent and
    // reading it here. Minimizes coupling between the components only to display
    // a single default unchangeable value.
    // NOTE: we can ignore zero, because the component won't even be rendered in this case
    if (options.length === 1) {
      onChange(options[0].value)
      return <Component options={options} defaultValue={options[0]} isDisabled={true} {...defaultProps} />
    }

    return defaultRender
  }

  return (
    <>
      <Label for={selectId}>{locale.createAssignment.role}</Label>
      <AsyncSelect
        id={selectId}
        query={getUserRolesAllowedToAssignToFacility}
        queryOptions={{ variables: { facilityUuid } }}
        dataToOptionsMapper={dataToOptionsMapper}
        conditionalRenderer={conditionalRenderer}
        placeholder={locale.createAssignment.chooseRole}
        onChange={setRoleValue}
      />
    </>
  )
}

RoleSelector.propTypes = {
  facilityUuid: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    createAssignment: PropTypes.shape({
      chooseRole: PropTypes.string.isRequired
    }).isRequired,
    roles: PropTypes.object.isRequired
  }).isRequired
}

export default withLocale(RoleSelector, { key: 'facility.userAssignments' })
