import { createContext } from 'react'

export const initialState = {
  modalOpen: false,
  modalClosable: true
}

export const DispatchContext = createContext(null)

export const actionIds = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  LOCK_MODAL: 'LOCK_MODAL',
  UNLOCK_MODAL: 'UNLOCK_MODAL'
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actionIds.OPEN_MODAL:
      return { ...state, modalOpen: true }
    case actionIds.CLOSE_MODAL:
      return { ...state, modalOpen: false }
    case actionIds.TOGGLE_MODAL:
      return { ...state, modalOpen: !state.modalOpen }
    case actionIds.LOCK_MODAL:
      return { ...state, modalClosable: false }
    case actionIds.UNLOCK_MODAL:
      return { ...state, modalClosable: true }
    default:
      return state
  }
}
