import React from 'react'

import { Collapse } from 'reactstrap'

import { faAngleDown, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'

import IconAllergic from '../../../../assets/guestgroup-icons/allergien-grau.svg'
import IconGenerations from '../../../../assets/guestgroup-icons/bequem-grau.svg'
import IconVisual from '../../../../assets/guestgroup-icons/blind-grau.svg'
import IconWalking from '../../../../assets/guestgroup-icons/gehbehindert-grau.svg'
import IconDeaf from '../../../../assets/guestgroup-icons/gehoerlos-grau.svg'
import IconMental from '../../../../assets/guestgroup-icons/kognitiv-grau.svg'
import IconWheelchair from '../../../../assets/guestgroup-icons/rollstuhlfahrer-grau.svg'

import '../../../../modules/download/_download.scss'

const langColors = {
  de: '#337ab7',
  en: '#e53012'
}

class LivePdf extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })
  getIconImage = id => {
    switch (id.slice(0, -3)) {
      case 'allergic':
        return IconAllergic
      case 'deaf':
        return IconDeaf
      case 'generations':
        return IconGenerations
      case 'mental':
        return IconMental
      case 'walking':
        return IconWalking
      case 'wheelchair':
        return IconWheelchair
      case 'visual':
        return IconVisual
      default:
        return ''
    }
  }

  render = () => {
    const {
      toggle,
      onEntering,
      onExiting,
      props: {
        locale,
        facility,
        facility: { permissions }
      },
      state: { status, isOpen }
    } = this

    return (
      <React.Fragment>
        {permissions.view_live_pdfs && (
          // TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
          <div className="collapse-container">
            <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
              <h3 className="collapse-headline">
                <span className="collapse-headline-text">{locale.download_barrierfree_pdfs}</span>
                <span className="collapse-icon icon-append">
                  <FontAwesomeIcon size="2x" icon={faAngleDown} />
                </span>
              </h3>
            </div>
            <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
              {Object.keys(facility.live_pdf.url_for_short_report).length && (
                <ul className="list-unstyled list-downloads d-flex flex-column border-bottom border-secondary pb-3 mb-5">
                  {Object.keys(facility.live_pdf.url_for_short_report)
                    .filter(key => key !== '__typename')
                    .map(lang => (
                      <ShortReportListItem
                        key={lang}
                        url={facility.live_pdf.url_for_short_report[lang]}
                        language={lang}
                      />
                    ))}
                </ul>
              )}
              <ul className="list-unstyled list-downloads d-flex flex-column">
                {Object.keys(facility.live_pdf).map((url, index) => (
                  <ListItem
                    getIconImage={this.getIconImage}
                    id={url}
                    key={index}
                    locale={locale}
                    url={facility.live_pdf[url]}
                  />
                ))}
              </ul>
            </Collapse>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const ShortReportListItem = withLocale(
  ({ url, language, locale }) => {
    if (!url || !language) {
      return null
    }
    return (
      <li>
        <a className="download file-only" href={url} target="_blank">
          <span className="download-icon">
            <FontAwesomeIcon className="file-icon" icon={faFilePdf} color={langColors[language]} size="2x" />
          </span>
          <span className="download-text">{locale[language]}</span>
        </a>
      </li>
    )
  },
  { key: 'live_pdf.short_reports' }
)

const ListItem = ({ id, locale, url, getIconImage }) => {
  if (typeof url !== 'string') return null

  let guest_group = id.split('url_for_')[1]
  let language =
    id.substr(id.length - 2) === 'en' || id.substr(id.length - 2) === 'de' ? id.substr(id.length - 2) : 'de'
  if (id !== '__typename') {
    return (
      <li className={guest_group === 'audit_report' ? 'order-1 pt-3 mt-5 border-top border-secondary' : ''}>
        <a className={`download ${guest_group === 'audit_report' ? 'file-only' : ''}`} href={url} target="_blank">
          <span className="download-icon">
            {guest_group !== 'audit_report' && (
              <img
                src={getIconImage(guest_group)}
                height="40"
                width="40"
                alt={locale.guest_group_description[guest_group]}
              />
            )}
            <FontAwesomeIcon
              className={`file-icon ${guest_group === 'audit_report' ? '' : 'absolute'}`}
              icon={faFilePdf}
              color={langColors[language]}
              size={guest_group === 'audit_report' ? '2x' : 'lg'}
            />
          </span>
          {locale.live_pdf[id]}
          <span className="download-text">
            {guest_group === 'audit_report' ? locale[guest_group] : locale.guest_group_description[guest_group]}
          </span>
        </a>
      </li>
    )
  } else {
    return null
  }
}

export default LivePdf
