import { useContext, useState } from 'react'

import { Alert, Form } from 'reactstrap'

import { useMutation } from '@apollo/client'
import { faBan, faLink } from '@fortawesome/free-solid-svg-icons'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { GraphQlError } from 'components/ui/genericComponents/errorHandling'
import AsyncUserSelect from 'components/ui/select/asyncUserSelect'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import { getAssignedUsersForFacility } from '../../../../assignedUsersList.graphql'
import { assignUserToFacility } from '../../createAssignment.graphql'
import { getPossibleContactUsersForFacility } from './facilityContactForm.graphql'

import { DispatchContext, actionIds } from '../../../createAssignmentModalContext'

const AssignExistingUser = ({ facilityUuid, locale }) => {
  const modalDispatch = useContext(DispatchContext)

  const closeModal = () => modalDispatch({ type: actionIds.CLOSE_MODAL })
  const lockModal = () => modalDispatch({ type: actionIds.LOCK_MODAL })
  const unlockModal = () => modalDispatch({ type: actionIds.UNLOCK_MODAL })

  const [user, setUser] = useState(null)
  const onChange = selectedUser => setUser(selectedUser)

  const [assignUserToFacilityMutation, { loading, error }] = useMutation(assignUserToFacility, {
    onError: unlockModal,
    refetchQueries: [getAssignedUsersForFacility],
    awaitRefetchQueries: true,
    onCompleted: _ => {
      unlockModal()
      closeModal()
    }
  })

  const dataToOptionsMapper = data =>
    data.facility.possibleContactUsers.map(user => ({ value: user.id, label: user.fullName, email: user.email }))

  const submitButtonLabel = user === null ? locale.submitBlocked : locale.submit({ name: user.label })

  const onFormSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    lockModal()

    assignUserToFacilityMutation({
      // REVIEW: should we extract the role to a constant? Where do we put it?
      variables: { facilityUuid, userId: user.value, role: 'FACILITY_CONTACT' }
    })
  }

  const errorMessage =
    error?.message === 'validationFailed' ? (
      // NOTE: we don't really have a standard format for validation errors, so we also don't have a standard component to handle them
      Object.keys(error.graphQLErrors[0].validationErrors).map(field => (
        <Alert color="danger" key={field}>
          {locale.validationErrors[field][error.graphQLErrors[0].validationErrors[field]]}
        </Alert>
      ))
    ) : (
      <GraphQlError error={error} />
    )

  return (
    <>
      <h3>{locale.heading}</h3>
      <Form onSubmit={onFormSubmit}>
        {errorMessage}
        <AsyncUserSelect
          isDisabled={loading}
          query={getPossibleContactUsersForFacility}
          queryOptions={{ variables: { facilityUuid } }}
          dataToOptionsMapper={dataToOptionsMapper}
          onChange={onChange}
          value={user}
        />
        <ProcessingButton
          type="submit"
          color="primary"
          processing={loading}
          label={submitButtonLabel}
          processing_label={submitButtonLabel}
          icon={user === null ? faBan : faLink}
          disabled={user === null}
          className="mt-3 float-right"
        />
      </Form>
    </>
  )
}

AssignExistingUser.propTypes = {
  facilityUuid: PropTypes.string.isRequired
}

export default withLocale(AssignExistingUser, {
  key: 'facility.userAssignments.createAssignment.forms.FACILITY_CONTACT.assignExistingUser'
})
