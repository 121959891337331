import { useState } from 'react'

import { Button, Collapse } from 'reactstrap'

import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

const CollapseWithHeader = ({ title, actionElement, children }) => {
  const [collapseOpen, setCollapseOpen] = useState(false)
  const toggle = () => setCollapseOpen(!collapseOpen)

  return (
    <div className="collapse-container">
      {/* NOTE: setting color to empty string, because that seems to be the only way to remove the default color class */}
      <Button color="" className={`collapse-header ${collapseOpen ? 'opened' : 'closed'}`} onClick={toggle}>
        <h3 className="collapse-headline">
          <span className="collapse-headline-text">{title}</span>
          <span className="collapse-icon icon-append">
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </span>
        </h3>

        {/* HACK: this is problematic, because a button element cannot contain another
        button or otherwise actionable element. This entire collapse markup and styling
        should be refactored, so the collapse header is a button and the actions are
        only visibly inside it but a sibling in the dom. For now it works, even if the browser complains a bit. */}
        <div className="list-actions">{actionElement}</div>
      </Button>
      <Collapse className="collapse-content" isOpen={collapseOpen}>
        {children}
      </Collapse>
    </div>
  )
}

CollapseWithHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actionElement: PropTypes.node,
  children: PropTypes.node
}

export default CollapseWithHeader
