import { CollapseWithHeader } from 'components/ui/genericComponents/collapseWithHeader'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import DataTable from './invoiceRecipient/dataTable'
import EditButton from './invoiceRecipient/editButton'

const InvoiceRecipient = ({ openUpdateForm, facilityUuid, locale }) => {
  const openForm = event => {
    event.stopPropagation()
    openUpdateForm()
  }

  return (
    <CollapseWithHeader
      title={locale.label}
      actionElement={<EditButton onClick={openForm} facilityUuid={facilityUuid} />}
    >
      <DataTable facilityUuid={facilityUuid} />
    </CollapseWithHeader>
  )
}

InvoiceRecipient.propTypes = {
  openUpdateForm: PropTypes.func.isRequired,
  facilityUuid: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    label: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(InvoiceRecipient, { key: 'facility.invoiceRecipient' })
