// additional components
import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { Badge, Col, Collapse, Row, Tooltip } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getFormDataWithoutSection } from '../facility.graphql'

import generateUUID from '../../../../helpers/guid'
import Loading from '../../../helper/loading/loading'
import CopyFormDatumModal from '../../../modules/modal/copyFormDatumModal'
import DeleteFormDatumModal from '../../../modules/modal/deleteFormDatumModal'

const Forms = ({ form, locale, facility_uuid, client, match, section_datum_uuid, permissions }) => (
  <React.Fragment>
    <Row className="with-seperator">
      <Col sm="12">
        <h4 className="headline">
          {form.form_sheet_number}&nbsp;{form.form_name} {!form.in_use && '(deaktiviert)'}
        </h4>
      </Col>
    </Row>
    <hr className="seperator seperator-small" />
    {
      <ul>
        {form.form_data.map(form_datum => (
          <FormDatum
            client={client}
            facility_uuid={facility_uuid}
            form_datum={form_datum}
            key={form_datum.uuid}
            locale={locale}
            permissions={permissions}
            match={match}
          />
        ))}
      </ul>
    }
  </React.Fragment>
)

const FormDatum = ({ form_datum, facility_uuid, client, locale, permissions, match }) => (
  <li>
    {form_datum.changeable && form_datum.viewable && (
      <Link
        to={
          match.url.includes('offline')
            ? `/offline-formdatum/edit/${form_datum.uuid}`
            : `/formdatum/edit/${form_datum.uuid}`
        }
      >
        {form_datum.name_de}
      </Link>
    )}
    {!form_datum.changeable && form_datum.viewable && (
      <Link to={`/formdatum/requirements/public/${form_datum.uuid}`}>{form_datum.name_de}</Link>
    )}
    {!form_datum.changeable && !form_datum.viewable && form_datum.name_de}

    {form_datum.changeable && (
      <CopyFormDatumModal
        client={client}
        facility_uuid={facility_uuid}
        name={form_datum.name_de}
        form_datum_uuid={form_datum.uuid}
        locale={locale}
      />
    )}
    {form_datum.deletable ? (
      <DeleteFormDatumModal
        client={client}
        facility_uuid={facility_uuid}
        name={form_datum.name_de}
        form_datum_uuid={form_datum.uuid}
        locale={locale}
      />
    ) : null}
  </li>
)

class WithoutSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      tooltip_open: false,
      tooltip_uuid: generateUUID(),
      status: 'closed'
    }
  }

  getForms = form_data => {
    let forms = []
    let actual_form_id = 0

    for (let i = 0; i < form_data.length; i++) {
      if (actual_form_id !== form_data[i].form_id) {
        actual_form_id = form_data[i].form_id
        forms.push({
          id: form_data[i].form_id,
          form_name: form_data[i].form_name,
          form_sheet_number: form_data[i].form_sheet_number,
          form_data: [form_data[i]],
          in_use: form_data[i].form.in_use
        })
      } else {
        for (let j = 0; j < forms.length; j++) {
          if (forms[j].id === actual_form_id) {
            forms[j].form_data.push(form_data[i])
          }
        }
      }
    }
    return forms
  }

  toggle = () =>
    this.setState({
      collapse: !this.state.collapse
    })

  toggleTooltip = () =>
    this.setState({
      tooltip_open: !this.state.tooltip_open
    })

  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const { client, facility_uuid, facility_version, locale, permissions, section_datum_uuid, match } = this.props

    return (
      // TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
      <div className="collapse-container">
        <div className={`collapse-header btn clearfix ${this.state.status}`} onClick={this.toggle}>
          <h3 className="collapse-headline">
            <span className="collapse-headline-text">Formulare ohne Teilbereichszuordnung</span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h3>
          <div className="list-actions">
            <Badge id={`tooltip-modal_${this.state.tooltip_uuid}`} pill>
              {this.props.count}
            </Badge>
            <Tooltip
              delay={500}
              placement="top"
              isOpen={this.state.tooltip_open}
              target={`tooltip-modal_${this.state.tooltip_uuid}`}
              toggle={this.toggleTooltip}
            >
              {this.props.locale.form_count}
            </Tooltip>
          </div>
        </div>
        <Collapse
          className="collapse-content"
          isOpen={this.state.collapse}
          onEntering={this.onEntering}
          onExiting={this.onExiting}
        >
          <Query
            query={getFormDataWithoutSection}
            variables={{
              uuid: facility_uuid,
              version_number: facility_version
            }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) return <Loading />
              if (error) return JSON.stringify(error)

              return this.getForms(data.facility.form_data_without_section).map(form => (
                <Forms
                  client={client}
                  facility_uuid={facility_uuid}
                  form={form}
                  match={match}
                  key={form.id}
                  locale={locale}
                  permissions={permissions}
                  section_datum_uuid={section_datum_uuid}
                />
              ))
            }}
          </Query>
        </Collapse>
      </div>
    )
  }
}

export default withApollo(WithoutSection)
