import { useRef, useState } from 'react'

import { Alert, Button, Collapse, UncontrolledTooltip } from 'reactstrap'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'
import PropTypes from 'prop-types'

const HeadingWithExtraInfo = ({ locale, globalLocale }) => {
  const moreInfoButtonRef = useRef()
  const [helpCollapseOpen, setHelpCollapseOpen] = useState(false)
  const toggleHelpCollapse = _ => setHelpCollapseOpen(!helpCollapseOpen)

  return (
    <>
      <h3>
        {locale.heading}
        <Button color="link" onClick={toggleHelpCollapse} className="py-0" innerRef={moreInfoButtonRef}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </Button>
        <UncontrolledTooltip target={moreInfoButtonRef} placement="right">
          {locale.moreInfo}
        </UncontrolledTooltip>
      </h3>

      <Collapse isOpen={helpCollapseOpen}>
        <Alert color="info" toggle={toggleHelpCollapse}>
          {globalLocale.contact_user.creation_description}
        </Alert>
      </Collapse>
    </>
  )
}

HeadingWithExtraInfo.propTypes = {
  locale: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    moreInfo: PropTypes.string.isRequired
  }).isRequired,
  globalLocale: PropTypes.shape({
    contact_user: PropTypes.shape({
      creation_description: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default withLocale(
  withLocale(HeadingWithExtraInfo, {
    key: 'facility.userAssignments.createAssignment.forms.FACILITY_CONTACT.createAndAssignNewUser'
  }),
  { attributeName: 'globalLocale' }
)
