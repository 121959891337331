import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'
import { components } from 'react-select'
import { Alert, Button, Col, Collapse, Container, Form, FormGroup, Label, Progress, Row, Table } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faAngleDown, faBan, faCheck, faCodeBranch, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { newSectionDatum } from '../sections/sections.graphql'
import { getFacility, getFacilitySectionData, triggerFacilityEvent } from './../facility.graphql'

import { getPropertyFromObject } from '../../../helper/helper-functions'
import { DD_MM_YYYY__HH_mm } from '../../../helper/helper-functions.jsx'
import { ProcessingButton } from '../../../modules/buttons/processing-button'
import ValidatedInput from '../../../modules/inputs/validated-input'
import ShowCommentsModal from '../../../modules/modal/showCommentsModal'
import RadioButtons from '../../../modules/radio-switch/radio-buttons'
import DynamicSelect from '../../../modules/selects/dynamicSelect'
import CommentCertification from './sections/comment_certification.jsx'
import VolumeUnitCostInput from './volumeUnitCostInput'

const Option = props => (
  <components.Option {...props}>
    <Fragment>
      {props.data.first_name} {props.data.last_name} <small className="d-block">{props.data.email}</small>
    </Fragment>
  </components.Option>
)

const Workflow = props => {
  let is_stopable = false
  let can_be_commented = false
  let permitted_events = []
  props.facility.current_workflow_state.permitted_events.map(item => {
    switch (item) {
      case 'cancel':
        is_stopable = true
        break
      case 'comment':
        can_be_commented = true
        break
      default:
        permitted_events.push({
          key: item,
          value: item,
          label: props.locale.certification_states[item],
          checked: false
        })
    }
  })

  return (
    <FacilityCertificationWorkflow
      {...props}
      can_be_commented={can_be_commented}
      is_stopable={is_stopable}
      permitted_events={permitted_events}
    />
  )
}

class FacilityCertificationWorkflow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ collapse: !this.state.collapse })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'Closed' })

  abortCertification = () => {
    this.setState({ processing: true })

    this.props.client
      .mutate({
        mutation: triggerFacilityEvent,
        variables: {
          uuid: this.props.facility.uuid,
          event: {
            event_name: 'cancel'
          }
        },
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.props.facility.uuid,
              version_number: ''
            }
          },
          {
            query: getFacilitySectionData,
            variables: {
              uuid: this.props.facility.uuid,
              version_number: ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(result => this.setState({ error: false, error_msg: '', processing: false }))
      .catch(error => this.onError(error))
  }

  render() {
    const {
      locale,
      facility,
      current_workflow_state,
      client,
      possible_collectors,
      workflow_history,
      charges_list,
      permitted_events,
      is_stopable,
      permissions,
      collection_desired_until,
      data_collected_at,
      offlineMode
    } = this.props
    const { status, collapse, error, error_msg, processing } = this.state
    const { toggle, onEntering, onExiting, abortCertification, props } = this
    let ProgressWrapper = offlineMode ? 'div' : Button

    return (
      // TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
      <div className="collapse-container">
        <ProgressWrapper
          className={`${
            offlineMode ? 'box box-small d-flex flex-row mt-0' : 'collapse-header'
          } progress-wrapper ${status}`}
          onClick={toggle}
        >
          <Progress value={facility.current_workflow_state.progress}>
            <span className="progress-text">{locale.certification_states[facility.current_workflow_state.state]}</span>
          </Progress>
          <span className="collapse-icon icon-ontop">
            {offlineMode ? '' : <FontAwesomeIcon className="text-primary-dark" size="2x" icon={faAngleDown} />}
          </span>
        </ProgressWrapper>
        {offlineMode ? (
          ''
        ) : (
          <Collapse className="collapse-content" isOpen={collapse} onEntering={onEntering} onExiting={onExiting}>
            {error ? <Alert color="danger">{error_msg}</Alert> : ''}

            {permitted_events && (
              <Fragment>
                <CommentCertification {...props} permission={facility.permissions.comment_workflow} />
                {facility.permissions.change_workflow && (
                  <Fragment>
                    <Row className="with-seperator">
                      <Col sm="6" lg="8">
                        <h2 className="headline">Zertifizierungsstatus ändern</h2>
                      </Col>
                      <Col sm="6" lg="4" className="text-right">
                        {is_stopable && (
                          <ProcessingButton
                            onClick={abortCertification}
                            label={locale.cancel_certification}
                            icon={faBan}
                            processing={processing}
                          />
                        )}
                      </Col>
                    </Row>
                    <hr className="seperator" />
                    <Container>
                      <Row>
                        {permitted_events.map((permitted_event, index) => (
                          <ChangeCertificationStatus
                            event_count={permitted_events.length}
                            key={permitted_event.key}
                            charges_list={facility.charges_list}
                            permitted_event={permitted_event}
                            index={index}
                            facility={facility}
                            locale={locale}
                            current_state={facility.current_workflow_state.state}
                            client={client}
                            collectors={facility.possible_collectors}
                            collection_desired_until={facility.collection_desired_until}
                            data_collected_at={facility.data_collected_at}
                          />
                        ))}
                      </Row>
                    </Container>
                  </Fragment>
                )}
              </Fragment>
            )}

            {facility.workflow_history.length > 0 && <CertificationHistory {...props} />}
          </Collapse>
        )}
      </div>
    )
  }
}

class ChangeCertificationStatus extends React.Component {
  state = {
    processing: false,
    error_msg: '',
    variables: {
      uuid: this.props.facility.uuid,
      event: {
        event_name: this.props.permitted_event.value,
        volumeUnitCost: this.props.facility.calculatedVolumeUnitCosts || 0
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.facility.calculatedVolumeUnitCosts !== this.state.variables.event.volumeUnitCost) {
      this.setState(
        update(this.state, {
          variables: { event: { volumeUnitCost: { $set: nextProps.facility.calculatedVolumeUnitCosts } } }
        })
      )
    }
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  setDateTime = (collection_date, collection_time) => {
    let set_time = ''

    if (collection_time && collection_date) {
      let time = collection_time === '' ? '00:00:00' : collection_time + ':00'
      let date = new Date(Date.parse(collection_date + 'T' + time + 'Z'))
      let userTimezoneOffset = date.getTimezoneOffset() * 60000
      set_time = new Date(date.getTime() + userTimezoneOffset).toISOString()
    }
    return set_time
  }
  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  convertToInt = value =>
    parseInt(
      String(value).indexOf(',') !== -1
        ? parseInt(Number.parseFloat(value.split(',').join('.')).toFixed(2) * 100, 10)
        : parseInt(value, 10) * 100,
      10
    )

  addFiles = event =>
    this.setState(
      update(this.state, {
        variables: { event: { file: { $set: event.target.files[0] } } }
      })
    )

  submit = e => {
    e.preventDefault()

    const {
      uuid,
      event: { collection_date, collection_time, ...rest }
    } = this.state.variables

    let variables = Object.assign({}, { uuid: this.props.facility.uuid, event: rest })
    if (variables.event.hasOwnProperty('collector_reward')) {
      variables.event.collector_reward = this.convertToInt(variables.event.collector_reward)
    }
    variables.event.collection_scheduled_at = this.setDateTime(collection_date, collection_time)

    this.setState({ processing: true })

    this.props.client
      .mutate({
        mutation: triggerFacilityEvent,
        variables,
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid: this.props.facility.uuid,
              version_number: ''
            }
          },
          {
            query: getFacilitySectionData,
            variables: {
              uuid: this.props.facility.uuid,
              version_number: ''
            }
          },
          {
            query: newSectionDatum,
            variables: { facility_uuid: this.props.facility.uuid },
            networkPolicy: 'network-only'
          }
        ],
        awaitRefetchQueries: true
      })
      .then(result => this.setState({ error: {}, error_msg: '', processing: false }))
      .catch(error => this.onError(error))
  }

  filterOption = ({ data: { first_name, last_name, email } }, filterString) =>
    `${first_name} ${last_name}`.toLowerCase().includes(filterString.toLowerCase()) ||
    email.toLowerCase().includes(filterString.toLowerCase())

  renderForms = () => {
    const {
      props: { event_count, locale, permitted_event },
      state: { variables, error_msg, processing, errors },
      addFiles,
      onChange,
      submit,
      renderForms
    } = this
    const props = {
      onChange,
      errors,
      processing,
      variables,
      locale,
      label_sm: 4,
      input_sm: 5
    }

    switch (this.props.permitted_event.value) {
      case 'send_contract':
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput
                required
                type="file"
                id="event.file"
                {...props}
                locale={locale.event.contract}
                variables={{}}
                onChange={addFiles}
              />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
          </Fragment>
        )
      case 'sign_contract':
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput
                required
                type="file"
                id="event.file"
                {...props}
                locale={locale.event.contract}
                variables={{}}
                onChange={addFiles}
              />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
          </Fragment>
        )
      case 'check_contract':
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      case 'inquire_collector':
        return (
          <Fragment>
            <TinyFormGroup>
              <Col sm="4" className="text-right">
                <Label className="control-label" for="collector_id">
                  {locale.collectors}
                </Label>
              </Col>
              <Col sm="4">
                <DynamicSelect
                  id="event.collector_id"
                  {...props}
                  isClearable={false}
                  options={this.props.collectors.map(collector => ({
                    id: 'event.collector_id',
                    value: collector.id,
                    first_name: collector.first_name,
                    last_name: collector.last_name,
                    label: collector.first_name + ' ' + collector.last_name,
                    email: collector.email,
                    target: {
                      id: 'event.collector_id',
                      value: collector.id
                    }
                  }))}
                  placeholder={'Erheber auswählen'}
                  selectOpts={{
                    filterOption: this.filterOption,
                    components: { Option }
                  }}
                />
              </Col>
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput unit="€" id="event.collector_reward" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput placeholder="tt.mm.jjjj" type="date" id="event.collection_desired_until" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      case 'decline_collection': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'accept_collection': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'schedule_collection': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput placeholder="tt.mm.jjjj" type="date" id="event.collection_date" {...props} />
              {!!getPropertyFromObject(errors, 'event.collection_scheduled_at') && (
                <span
                  style={{
                    marginTop: '0.25rem',
                    fontSize: '80%',
                    color: '#e53012'
                  }}
                >
                  {getPropertyFromObject(errors, 'event.collection_scheduled_at')}
                </span>
              )}
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput placeholder="--:--" type="time" id="event.collection_time" {...props} />
              {!!getPropertyFromObject(errors, 'event.collection_scheduled_at') && (
                <span
                  style={{
                    marginTop: '0.25rem',
                    fontSize: '80%',
                    color: '#e53012'
                  }}
                >
                  {getPropertyFromObject(errors, 'event.collection_scheduled_at')}
                </span>
              )}
            </TinyFormGroup>

            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'start_collection': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'finish_collection': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }

      case 'ask_question': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }

      case 'mark_as_ready_for_report': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }

      case 'check_data_and_send_draft': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'decline_draft': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'confirm_draft': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'send_certificate': {
        return (
          <Fragment>
            <TinyFormGroup>
              <VolumeUnitCostInput
                calculatedFacilitySize={this.props.facility.calculatedFacilitySize}
                formDataCount={this.props.facility.form_data_count}
                calculatedVolumeUnitCosts={this.props.facility.calculatedVolumeUnitCosts}
                duplicationJobsRunning={this.props.facility.duplication_jobs_running}
                recertification={this.props.facility.recertification}
                {...props}
              />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'forward_certificate': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'bill': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
            <TinyFormGroup>
              <Col lg="4" className="text-lg-right">
                <Label className="control-label" for={'event.listed'}>
                  {getPropertyFromObject(locale, 'event.listed')}
                </Label>
              </Col>
              <Col lg="5">
                <RadioButtons id={'event.listed'} variables={this.state.variables} onChange={onChange} />
              </Col>
            </TinyFormGroup>
          </Fragment>
        )
      }
      case 'start_recertification': {
        return (
          <Fragment>
            <TinyFormGroup>
              <ValidatedInput id="event.comment" type="textarea" {...props} />
            </TinyFormGroup>
            <TinyFormGroup>
              <ValidatedInput required type="file" id="event.file" {...props} variables={{}} onChange={addFiles} />
            </TinyFormGroup>
          </Fragment>
        )
      }
    }
  }

  render() {
    const {
      props: { event_count, locale, permitted_event },
      state: { variables, error_msg, processing, errors },
      addFiles,
      onChange,
      submit,
      renderForms
    } = this

    const props = { onChange, errors, processing, variables, locale }

    return (
      <React.Fragment>
        <Col sm={event_count > 1 ? { size: 6 } : { size: 12 }}>
          {this.state.error_msg && <Alert color="danger">{this.state.error_msg}</Alert>}
          <Form onSubmit={submit}>
            <FormGroup>
              <Container>
                <Row>
                  <Col sm="4" className="text-right">
                    <p className="control-label switch-check-label">{locale.change_status}</p>
                  </Col>
                  <Col sm="6" lg={event_count > 1 ? { size: 8 } : { size: 4 }}>
                    <p className="form-control">{permitted_event.label}</p>
                  </Col>
                </Row>
              </Container>
            </FormGroup>

            {renderForms()}

            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm={{ size: 8, offset: 4 }}>
                    <ProcessingButton
                      type="submit"
                      processing={processing}
                      label={permitted_event.label}
                      icon={faCheck}
                    />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Col>
      </React.Fragment>
    )
  }
}

class CertificationHistory extends React.Component {
  render() {
    const { props } = this
    return (
      <React.Fragment>
        <h2>{props.locale.history}</h2>
        <hr className="seperator" />
        <Table striped responsive bordered>
          <thead className="thead-light">
            <tr>
              <th>{props.locale.date}</th>
              <th>{props.locale.status}</th>
              <th>{props.locale.changed_from}</th>
              <th>{props.locale.comments}</th>
              <th className="text-center">{props.locale.file}</th>
              <th>Version</th>
              {/*<th>Status betrachten</th>*/}
            </tr>
          </thead>
          <tbody>
            {props.facility.workflow_history.map((row, index) => (
              <HistoryTable key={index} table={row} {...props} />
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    )
  }
}

const HistoryTable = ({ table, locale }) => (
  <tr>
    <td>{DD_MM_YYYY__HH_mm(table?.created_at)}</td>
    <td>{locale?.certification_states[table.new_status]}</td>
    <td>{table.user ? `${table.user.first_name} ${table.user.last_name}` : locale.user.system}</td>
    <td>
      <ShowCommentsModal locale={locale} comments={table?.comments} />
    </td>
    <td className="text-center">
      {table?.file?.url && (
        <a href={table?.file?.url} target="_blank" title={table?.file?.filename}>
          <FontAwesomeIcon icon={faDownload} />
        </a>
      )}
    </td>
    <td>
      {table.previous_version && (
        <Link to={`/facility/${table.previous_version.uuid}/${table.previous_version.version_number}`}>
          {table.previous_version.version_number} <FontAwesomeIcon icon={faCodeBranch} />
        </Link>
      )}
    </td>
  </tr>
)

const TinyFormGroup = ({ children }) => (
  <FormGroup>
    <Container>
      <Row>{children}</Row>
    </Container>
  </FormGroup>
)

export default withApollo(Workflow)
