import { withLocale } from 'locale/index'

import AsyncSelect from './asyncSelect'
import Option from './asyncUserSelect/option'

const filterOption = ({ data: { label, email } }, filterString) =>
  `${label}`.toLowerCase().includes(filterString.toLowerCase()) ||
  email.toLowerCase().includes(filterString.toLowerCase())

const AsyncUserSelect = ({ locale, ...props }) => {
  return (
    <AsyncSelect
      components={{ Option }}
      placeholder={locale.placeholder}
      noOptionsMessage={_ => locale.nothingFound}
      filterOption={filterOption}
      {...props}
    />
  )
}

AsyncUserSelect.propTypes = {
  // NOTE: the `dataToOptionsMapper` prop, defined on the AsyncSelect, needs to
  // return an array which elements have at least the keys `label`, `value` and
  // `email`, because the custom Option-Component and the filter function uses
  // it.
  //
  // ```js
  // { label: 'User name', value: 'User ID', email: 'User email' }
  // ```
  ...AsyncSelect.propTypes
}

export default withLocale(AsyncUserSelect, { key: 'userInterface.select.asyncUserSelect' })
