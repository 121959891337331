// additional components
import { Component, Fragment } from 'react'

// ReactStrap Components
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TokenAuth } from 'helpers/token-auth'

import { auth } from '../../../config/auth.js'
import { UserDataQuery } from './graphql'

// CSS
import '../../modules/box/_box.scss'
import './_login.scss'

class AuthenticationFailedError extends Error {
  name = 'AuthenticationFailedError'
  message = 'Email oder Passwort nicht korrekt.'
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: false,
      error_msg: ''
    }
  }

  change = event => {
    this.setState({ [event.target.id]: event.target.value })
  }
  fetchLogin = () => {
    auth.options.body = JSON.stringify(this.state)
    auth.options.method = 'POST'

    fetch(`${auth.uri}/sign_in`, auth.options)
      .then(this.handleStatusCodes)
      .then(this.updateAuthStorage)
      .then(this.updateUserStorage)
      .then(this.redirectAfterLogin)
      .catch(error => {
        if (error instanceof AuthenticationFailedError) {
          console.debug(error)
        } else {
          throw error
        }
      })
  }

  handleStatusCodes = response => {
    return new Promise((resolve, reject) => {
      if (response.status === 200) {
        resolve(response)
      }
      if (response.status === 401) {
        const error = new AuthenticationFailedError()
        this.setState({
          error_msg: error.message,
          error: true
        })
        reject(error)
      }
    })
  }
  updateAuthStorage = ({ headers }) => {
    const auth = {
      client: headers.get('client'),
      'access-token': headers.get('access-token'),
      uid: headers.get('uid')
    }
    localStorage.setItem('auth', JSON.stringify(auth))
  }

  updateUserStorage = () =>
    this.props.client.query({ query: UserDataQuery }).then(response => {
      this.updateUser(response.data)
      this.updatePermissions(response.data)
    })

  redirectAfterLogin = () => {
    const location = this.props.location
    if (location.state && location.state.afterSignInPath) {
      this.props.history.push(location.state.afterSignInPath)
    } else {
      this.props.history.push('/')
    }
  }

  updateUser = TokenAuth.setUser

  updatePermissions = ({ user }) => {
    let _permissions = {}
    user.permissions.map(permission => {
      _permissions[permission.key] = permission.permitted
    })
    localStorage.setItem('permissions', JSON.stringify(_permissions))
  }

  render = () => {
    TokenAuth.isLoggedIn() && this.props.history.push('/facilities')

    return (
      <Fragment>
        <div className="page-wrapper">
          <Row className="page-header">
            <Col>
              <h1>Anmeldung</h1>
            </Col>
          </Row>
          <hr className="seperator" />
          <Form className="box">
            {this.state.error ? <Alert color="danger">{this.state.error_msg}</Alert> : ''}
            {this.props.online ? null : (
              <Alert color="warning">
                Bitte prüfen Sie Ihre Internetverbindung. Anmeldungen in der Datenbank benötigen eine aktive
                Internetverbindung.
              </Alert>
            )}
            <FormGroup>
              <Container>
                <Row>
                  <Col sm="3" className="text-right">
                    <Label className="control-label" for="email">
                      E-Mail-Adresse
                    </Label>
                  </Col>
                  <Col sm="5">
                    <Input type="email" name="email" id="email" placeholder="Email" onChange={this.change} />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
            <FormGroup>
              <Container>
                <Row>
                  <Col sm="3" className="text-right">
                    <Label className="control-label" for="password">
                      Passwort
                    </Label>
                  </Col>
                  <Col sm="5">
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Passwort"
                      onChange={this.change}
                    />
                  </Col>
                </Row>
              </Container>
            </FormGroup>
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm={{ size: 2, offset: 2 }}>
                    <Button
                      block
                      type="submit"
                      className="btn-labeled btn-login"
                      color="primary-light"
                      onClick={event => {
                        event.preventDefault()
                        this.fetchLogin()
                      }}
                    >
                      <FontAwesomeIcon className="icon-prepend" icon={faSignInAlt} />
                      <span>Login</span>
                    </Button>
                  </Col>
                  <Col sm={{ size: 3 }}>
                    <Button
                      block
                      type="submit"
                      className="btn-labeled btn-login"
                      color="primary-light"
                      onClick={() => this.props.history.push('/einrichtung-erstellen')}
                    >
                      <FontAwesomeIcon className="icon-prepend" icon={faSignInAlt} />
                      <span>Einrichtung registrieren</span>
                    </Button>
                  </Col>
                  <Col sm={{ size: 3 }}>
                    <Button
                      outline
                      block
                      type="submit"
                      className="btn-labeled btn-login"
                      color="primary-light"
                      onClick={() => this.props.history.push('/password/new')}
                    >
                      <FontAwesomeIcon className="icon-prepend" icon={faSignInAlt} />
                      <span>Passwort vergessen?</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </div>
        <Container>
          <Row>
            <div style={{ fontSize: '12px', color: 'grey', textAlign: 'center' }}>
              Diese Website wird von Betrieben, Erhebern und Lizenznehmern des Systems "Reisen für Alle" genutzt.
              Hierfür ist eine Anmeldung erforderlich. An einer Kennzeichnung interessierte Betriebe können sich
              kostenfrei registrieren (Button "Einrichtung registrieren") und erhalten dann ein Angebot sowie weitere
              Informationen. Ausführliche Informationen zum Kennzeichnungssystem "Reisen für Alle" finden Sie auf der
              Website:
              <a href="https://www.reisen-fuer-alle.de"> www.reisen-fuer-alle</a>
            </div>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default withApollo(Login)
