import React, { Fragment } from 'react'

import { Button, Collapse, Table, Tooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TableField } from '../partials'

class SearchCriteria extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const {
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      props: {
        facility: { permissions, grouped_search_criteria },
        locale,
        editContentRight
      },
      state: { status, isOpen, tooltip_open }
    } = this

    return (
      // TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
      <div className="collapse-container">
        <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
          <h3 className="collapse-headline">
            <span className="collapse-headline-text">{locale.search_criteria.plural}</span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h3>
          <div className="list-actions">
            {permissions.change_search_criteria && (
              <Fragment>
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`search_criteria`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip
                  delay={500}
                  placement="top"
                  isOpen={tooltip_open}
                  target={`search_criteria`}
                  toggle={toggleTooltip}
                >
                  {locale.edit}
                </Tooltip>
              </Fragment>
            )}
          </div>
        </div>
        <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
          <Table striped responsive bordered>
            <tbody>
              {grouped_search_criteria.map((search_criteria_group, i) => (
                <TableField
                  key={i}
                  label={search_criteria_group.guest_group.label}
                  value={
                    search_criteria_group.search_criteria
                      .filter(item => item.chosen)
                      .map(search_criterium => search_criterium.name)
                      .join(', ') || locale.search_criteria.no_chosen
                  }
                />
              ))}
            </tbody>
          </Table>
        </Collapse>
      </div>
    )
  }
}

export default SearchCriteria
