import { Button, UncontrolledTooltip } from 'reactstrap'

import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GuestGroupImage, ReportingResultIcon } from './helper'

import './_reportings.scss'

const Summary = props => (
  // TODO: container could be refactored to
  // `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
  // Probably with some adjustments or a new element, because it should only
  // LOOK like the header but has no collapsible content.
  <div className="collapse-container collapse-reportings">
    <div className={`collapse-header btn d-xl-flex clearfix`}>
      <h2 className="h4 collapse-headline d-xl-flex align-items-center">
        <span className="collapse-headline-text">{props.locale['reporting_result']}</span>
      </h2>
      <div className="reportings-summary d-flex flex-wrap flex-xl-nowrap justify-content-center justify-content-xl-end">
        <SummaryIcons {...props} />
      </div>

      {props.match.url.includes('offline') ? null : (
        <div className="list-actions ml-4 pl-2 border-left border-secondary">
          <Button
            id="goto_reportings"
            size="sm"
            className="btn-transparent btn-icon-only btn"
            color="link"
            onClick={() => props.history.push(`/reportings/${props.facility.uuid}/${props.facility.version_number}`)}
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
          <UncontrolledTooltip delay={500} target={'goto_reportings'} children={'Prüfberichte anzeigen'} />
        </div>
      )}
    </div>
  </div>
)

const SummaryIcons = props =>
  props.facility.reportings.map(item => {
    const key = item.guest_group.key + '-' + item.category + '-' + props.facility.uuid
    return (
      <div className="summary-item" key={item.guest_group.key + '-' + item.category}>
        <GuestGroupImage
          category={item.category}
          guest_group={item.guest_group}
          locale={props.locale}
          uuid={props.facility.uuid}
          width="40"
        />
        <ReportingResultIcon calculated_result={item.calculated_result} id={key} />
      </div>
    )
  })
export default Summary
