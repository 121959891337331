import React from 'react'

import { Collapse, Table, UncontrolledTooltip } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DD_MM_YYYY } from '../../../helper/helper-functions'
import Create from './create'
import Delete from './delete'

class Training extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ collapse: !this.state.collapse })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const {
      toggle,
      onEntering,
      onExiting,
      state: { status, collapse },
      props,
      props: {
        locale,
        facility: { uuid, permissions, training_participants, training_completed }
      }
    } = this
    return (
      // TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
      <div className="collapse-container">
        <div className={`collapse-header btn d-flex align-items-center flex-row clearfix ${status}`} onClick={toggle}>
          <h3 className="h3 collapse-headline">
            <span className="collapse-headline-text">{locale['training_information']}</span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h3>
          <div className="align-items-center d-flex ml-auto mb-0 text-right">
            <span
              className={`text-primary-dark font-weight-normal p-3 badge text-right ${
                training_completed ? 'badge-success' : 'badge-warning'
              }`}
            >
              <span>{training_completed ? locale.training_completed : locale.training_pending}</span>
            </span>
            {permissions.update_training_participant && (
              <div className="list-actions ml-4 pl-2 border-left border-secondary">
                <Create {...props} />
              </div>
            )}
          </div>
        </div>
        <Collapse className="collapse-content" isOpen={collapse} onEntering={onEntering} onExiting={onExiting}>
          <Table striped responsive bordered>
            <thead className="thead-light">
              <tr>
                <th>{locale.name}</th>
                <th>{locale.date}</th>
                <th>{locale.type}</th>
                <th>{locale.seminar_number}</th>
                <th>{locale.comment}</th>
                {permissions.update_training_participant && <th> </th>}
              </tr>
            </thead>
            <tbody>
              {training_participants.map((training_participant, i) =>
                training_participant.training_participations.map((training_participation, j) => (
                  <TrainingTable
                    key={`${i}-${j}`}
                    training_participant={training_participant}
                    training_participation={training_participation}
                    {...props}
                  />
                ))
              )}
            </tbody>
          </Table>
        </Collapse>
      </div>
    )
  }
}

const TrainingTable = props => (
  <tr>
    <td>
      {props.training_participant?.first_name || ''} {props.training_participant?.last_name || ''}
    </td>
    <td>{DD_MM_YYYY(props.training_participation?.passed_at)}</td>
    <td>{KindName(props.training_participation?.kind)}</td>
    <td>{props.training_participation?.seminar_number || '-'}</td>
    <td>
      <TooltipComment
        target={'tooltip-' + props.training_participant.uuid + props.training_participation.uuid}
        comment={props.training_participation.comment}
        trunc={25}
      />
    </td>
    {props.facility.permissions.update_training_participant && (
      <td className="text-center">
        <Delete
          uuid={props.training_participation.uuid}
          kind={KindName(props.training_participation?.kind)}
          name={`${props.training_participant?.first_name} ${props.training_participant?.last_name}`}
          {...props}
        />
      </td>
    )}
  </tr>
)

const TooltipComment = ({ comment, target, trunc }) =>
  comment?.length <= trunc ? (
    <p>{comment || '-'}</p>
  ) : (
    <React.Fragment>
      <p id={target}>{comment?.substr(0, trunc) + '\u2026' || '-'}</p>
      <UncontrolledTooltip placement="top" target={target}>
        {comment}
      </UncontrolledTooltip>
    </React.Fragment>
  )

const KindName = kind => {
  if (kind === 'collector') {
    return 'DSFT-Erheberschulung'
  }
  if (kind === 'online') {
    return 'Online-Schulung Reisen für Alle'
  }
  if (kind === 'other') {
    return 'Sonstige Schulung'
  }
  if (kind === 'presence') {
    return 'DSFT-Präsenzseminar Reisen für Alle'
  }
  return '-'
}

export default withApollo(Training)
