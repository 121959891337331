import { EmailLink, PhoneLink } from 'components/ui/genericComponents/links'
import { TitledBoxWithActions } from 'components/ui/genericComponents/titledBoxWithActions'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import RemoveAssignment from './userAssignment/removeAssignment'

const UserAssignment = ({ id, removalPermitted, role, user, locale }) => {
  const actionItems = removalPermitted ? <RemoveAssignment id={id} fullName={user.full_name} role={role} /> : null

  return (
    <TitledBoxWithActions title={locale.roles[role]} actionItems={actionItems}>
      {user.full_name}
      <br />
      <EmailLink email={user.email} />
      <br />
      <PhoneLink phone={user.phone} />
    </TitledBoxWithActions>
  )
}

UserAssignment.propTypes = {
  id: PropTypes.string.isRequired,
  removalPermitted: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  user: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string
  }).isRequired,
  actions: PropTypes.node
}

export default withLocale(UserAssignment, { key: 'facility.userAssignments' })
