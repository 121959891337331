import { useRef } from 'react'

import { Alert, UncontrolledTooltip } from 'reactstrap'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

const ErrorIconWithTooltip = ({ errorMessage, ...props }) => {
  const iconRef = useRef()

  return (
    <>
      <span {...props} className={`text-danger ${props.className}`} ref={iconRef}>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </span>
      <UncontrolledTooltip placement="top" target={iconRef} boundariesElement="viewport" autohide={false}>
        <Alert color="danger" className="m-0">
          {errorMessage}
        </Alert>
      </UncontrolledTooltip>
    </>
  )
}

ErrorIconWithTooltip.propTypes = {
  errorMessage: PropTypes.string.isRequired
}

export default ErrorIconWithTooltip
