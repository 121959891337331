import { isValidElement } from 'react'

const ERROR_TYPE_NO_ERROR = 'NO_ERROR'
const ERROR_TYPE_JS_ERROR = 'JS_ERROR'
const ERROR_TYPE_GENERIC_ERROR_MESSAGE_JSX_NODE = 'GENERIC_ERROR_MESSAGE_JSX_NODE'
const ERROR_TYPE_GRAPHQL_API_ERROR = 'GRAPHQL_API_ERROR'
const ERROR_TYPE_UNKNOWN = 'UNKNOWN'

const getErrorType = error => {
  if (error === undefined || error === null) return ERROR_TYPE_NO_ERROR

  if (error instanceof Error) return ERROR_TYPE_JS_ERROR

  if (typeof error === 'array') return ERROR_TYPE_GRAPHQL_API_ERROR

  if (isValidElement(error)) return ERROR_TYPE_GENERIC_ERROR_MESSAGE_JSX_NODE

  return ERROR_TYPE_UNKNOWN
}

const mapGraphQlErrorResultToMessages = polymorphicError => {
  const errorType = getErrorType(polymorphicError)

  switch (errorType) {
    case ERROR_TYPE_NO_ERROR:
      return []
    case ERROR_TYPE_JS_ERROR:
      return [polymorphicError.message]
    case ERROR_TYPE_GENERIC_ERROR_MESSAGE_JSX_NODE:
      return [polymorphicError]
    case ERROR_TYPE_GRAPHQL_API_ERROR:
      // NOTE: the set ensures, the messages are unique
      return [...new Set(polymorphicError.map(graphQlApiError => graphQlApiError.message))]
    default:
      return ['An unknown error occurred']
  }
}

export default mapGraphQlErrorResultToMessages
