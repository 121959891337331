import React, { Fragment } from 'react'

import RichTextEditor from 'react-rte'
import { Button, Col, Collapse, Container, FormGroup, Input, Row } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import update from 'immutability-helper'

import { updateShortReport } from './short_reports.graphql'

import { ProcessingButton } from '../../../modules/buttons/processing-button'
import { Generate } from './generate'

const ShortReports = props => (
  <Fragment>
    <ShortReport report_type={'wheelchair_and_walking'} {...props} />
    <ShortReport report_type={'deaf_and_partially_deaf'} {...props} />
    <ShortReport report_type={'visual_and_partially_visual'} {...props} />
    <ShortReport report_type={'mental'} {...props} />
    <ShortReport report_type={'allergic'} {...props} />
    <ShortReport report_type={'generations'} {...props} />
  </Fragment>
)

class ShortReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ collapse: !this.state.collapse })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'Closed' })
  render = () => {
    const { report_type, locale } = this.props
    const { toggle, onEntering, onExiting, props } = this
    const { collapse, status } = this.state
    return (
      // TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
      <div className="collapse-container">
        <Button className={`collapse-header progress-wrapper ${status}`} onClick={toggle}>
          <h3 className="collapse-headline d-xl-flex align-items-center">
            <span className="collapse-headline-text">
              {locale.short_report.plural}: {locale.short_report[report_type]}
            </span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h3>
        </Button>
        <Collapse className="collapse-content" isOpen={collapse} onEntering={onEntering} onExiting={onExiting}>
          <ShortReportEditor report_type={report_type} language="de" {...props} />
          <ShortReportEditor report_type={report_type} language="en" {...props} />
        </Collapse>
      </div>
    )
  }
}

class ShortReportEditor extends React.Component {
  constructor(props) {
    super(props)
    const key = `${this.props.report_type}_${this.props.language}`
    this.state = {
      collapse: false,
      processing: false,
      key,
      html: props.facility.short_report[key] || '',
      value: RichTextEditor.createValueFromString(props.facility.short_report[key] || '', 'html'),
      variables: {
        facility_uuid: props.facility.uuid,
        short_report: {
          [key]: RichTextEditor.createValueFromString(props.facility.short_report[key] || '', 'html')
        }
      }
    }
  }

  onChange = value =>
    this.setState(
      update(this.state, {
        html: {
          $set: value.toString('html')
        },
        value: {
          $set: value
        }
      })
    )

  submit = () => {
    this.setState({ processing: true })
    const { variables, value, key } = this.state
    const { client, report_type } = this.props
    variables.short_report[key] = value.toString('html')
    client
      .mutate({ mutation: updateShortReport, variables })
      .then(() => {
        this.setState({ processing: false })
      })
      .catch(error => console.log(report_type, error))
  }

  toolbarConfig = () => ({
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Fett', style: 'BOLD' },
      { label: 'Kursiv', style: 'ITALIC' },
      { label: 'Durchgestrichen', style: 'STRIKETHROUGH' },
      { label: 'Unterstrichen', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Text', style: 'unstyled' },
      { label: 'Überschrift H3', style: 'header-three' },
      { label: 'Überschrift H4', style: 'header-four' },
      { label: 'Überschrift H5', style: 'header-five' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'Liste (unsortiert)', style: 'unordered-list-item' },
      { label: 'Liste (sortiert)', style: 'ordered-list-item' }
    ]
  })

  changeHTML = event => {
    const key = `${this.props.report_type}_${this.props.language}`
    this.setState(
      update(this.state, {
        html: {
          $set: event.target.value
        },
        value: {
          $set: RichTextEditor.createValueFromString(event.target.value || '', 'html')
        },
        variables: {
          short_report: {
            [key]: {
              $set: RichTextEditor.createValueFromString(event.target.value || '', 'html')
            }
          }
        }
      })
    )
  }

  toggleHTML = () => this.setState({ collapse: !this.state.collapse })

  render = () => {
    const { locale, report_type, language, facility } = this.props
    const { value, processing, html } = this.state
    const { onChange, submit, toolbarConfig, changeHTML } = this
    return (
      <Fragment>
        <h4 className="text-center">{locale.short_report.label[language]}</h4>
        <Collapse isOpen={this.state.collapse}>
          <FormGroup>
            <Input
              disabled={!facility.permissions.change_short_report}
              rows={10}
              placeholder="Fügen Sie eigenen HTML-Code hier ein"
              value={html}
              type="textarea"
              name="html"
              onChange={changeHTML}
            />
          </FormGroup>
        </Collapse>
        <RichTextEditor
          customControls={[
            (action, getValue) => {
              return (
                <div
                  key={`collapse_${report_type}_${language}`}
                  onClick={this.toggleHTML}
                  className="ButtonGroup__root___3lEAn"
                >
                  <div className="ButtonWrap__root___1EO_R">
                    <button
                      type="button"
                      title="HTML einfügen"
                      className="IconButton__root___3tqZW Button__root___1gz0c"
                      disabled=""
                    >
                      <span>
                        <strong>HTML</strong>
                      </span>
                    </button>
                  </div>
                </div>
              )
            }
          ]}
          disabled={!facility.permissions.change_short_report}
          toolbarConfig={toolbarConfig()}
          value={value}
          onChange={onChange}
          placeholder={`${locale.short_report.placeholder} ${locale.short_report[report_type]} (${language})`}
        />
        <br />
        {facility.permissions.change_short_report && (
          <FormGroup>
            <Container>
              <Row>
                <Col sm={12} className="text-right">
                  {language == 'de' && (
                    <Generate
                      change={value => changeHTML({ target: { value } })}
                      facility_uuid={facility.uuid}
                      guest_group_key={report_type}
                    />
                  )}
                  <ProcessingButton label={locale.save} onClick={submit} processing={processing} />
                </Col>
              </Row>
            </Container>
          </FormGroup>
        )}
      </Fragment>
    )
  }
}

export default withApollo(ShortReports)
