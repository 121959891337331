import { Button, Col, Container, FormGroup, Row } from 'reactstrap'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

const Actions = ({ closeForm, currentlySaving, locale }) => (
  <FormGroup className="form-action">
    <Container>
      <Row>
        <Col sm="5">
          <Button color="link" type="button" onClick={closeForm}>
            {locale.cancel}
          </Button>
        </Col>
        <Col sm="7" className="text-right">
          <ProcessingButton type="submit" processing={currentlySaving} label={locale.save} />
        </Col>
      </Row>
    </Container>
  </FormGroup>
)

Actions.propTypes = {
  closeForm: PropTypes.func.isRequired,
  currentlySaving: PropTypes.bool,
  locale: PropTypes.shape({
    cancel: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(Actions)
