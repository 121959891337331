import { components } from 'react-select'

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Option = props => (
  <components.Option {...props}>
    <>
      {props.data.label}
      <small className="d-block">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        {props.data.email}
      </small>
    </>
  </components.Option>
)

export default Option
