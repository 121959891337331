import { relayStylePagination } from '@apollo/client/utilities'

export default {
  keyFields: ['uuid', 'version_number'],
  fields: {
    section_data: {
      merge(existing, incoming) {
        return incoming
      }
    },
    form_data_without_section: {
      merge(existing, incoming) {
        return incoming
      }
    },
    third_party_ids: {
      merge(existing, incoming) {
        return incoming
      }
    },
    base_data: {
      merge(existing, incoming) {
        return incoming
      }
    },
    training_participants: {
      merge(existing, incoming) {
        return incoming
      }
    },
    charges_list: {
      merge(existing, incoming) {
        return incoming
      }
    },
    certification_data: {
      merge(existing, incoming) {
        return incoming
      }
    },
    possible_collectors: {
      merge(existing, incoming) {
        return incoming
      }
    },
    possible_license_owner_users: {
      merge(existing, incoming) {
        return incoming
      }
    },
    downloads: {
      merge(existing, incoming) {
        return incoming
      }
    },
    images: {
      merge(existing, incoming) {
        return incoming
      }
    },
    userAssignments: relayStylePagination() // NOTE: this only works, when we request items via `edges`, not `nodes`
  }
}
