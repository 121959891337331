import { useRef, useState } from 'react'

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'

import { useMutation } from '@apollo/client'
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ProcessingButton } from 'components/modules/buttons/processing-button'
import { GraphQlError } from 'components/ui/genericComponents/errorHandling'
import { withLocale } from 'locale/index'
import PropTypes from 'prop-types'

import { getAssignedUsersForFacility } from '../../assignedUsersList.graphql'
import { removeUserAssignmentFromFacility } from './removeAssignment.graphql'

const RemoveAssignment = ({ id, fullName, role, locale, globalLocale }) => {
  const buttonRef = useRef()

  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = () => setModalOpen(!modalOpen)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const [removeUserAssignmentMutation, { loading, error }] = useMutation(removeUserAssignmentFromFacility, {
    onError: _ => {}, // NOTE: we show the error, but don't handle it otherwise
    refetchQueries: [getAssignedUsersForFacility],
    awaitRefetchQueries: true
  })

  const removeUserAssignment = _ => {
    removeUserAssignmentMutation({ variables: { assignmentId: id } })
  }

  return (
    <>
      <Button color="primary" size="sm" innerRef={buttonRef} onClick={openModal}>
        <FontAwesomeIcon icon={faLinkSlash} />
      </Button>
      <UncontrolledTooltip placement="top" target={buttonRef} boundariesElement="viewport">
        {locale.removeAssignment.heading}
      </UncontrolledTooltip>
      <Modal
        size="sm"
        centered
        isOpen={modalOpen}
        toggle={loading ? undefined : toggleModal} // NOTE: disabling the toggle while the mutation runs, so clicking the background cannot close the modal
      >
        <ModalHeader>{locale.removeAssignment.heading}</ModalHeader>
        <ModalBody>
          {locale.removeAssignment.message({ name: fullName, role: locale.roles[role] })}
          <GraphQlError error={error} />
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={closeModal} disabled={loading}>
            {globalLocale.cancel}
          </Button>
          <ProcessingButton
            color="danger"
            icon={faLinkSlash}
            onClick={removeUserAssignment}
            processing={loading}
            label={locale.removeAssignment.confirm}
            processing_label={locale.removeAssignment.confirm}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

RemoveAssignment.propTypes = {
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  locale: PropTypes.object.isRequired,
  globalLocale: PropTypes.shape({
    cancel: PropTypes.string.isRequired
  }).isRequired
}

export default withLocale(withLocale(RemoveAssignment, { key: 'facility.userAssignments' }), {
  attributeName: 'globalLocale'
})
