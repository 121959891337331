import React, { useId } from 'react'

import { Badge, Col, Collapse, Row, UncontrolledTooltip } from 'reactstrap'

import { faAngleDown, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { EmailLink, PhoneLink } from 'components/ui/genericComponents/links'
import { TitledBoxWithActions } from 'components/ui/genericComponents/titledBoxWithActions'

import { AssignedUsers } from '../assignedUsers'
import Create from './create'
import Update from './update'
import UpdateData from './update-data'

class ContactPersonCertification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ collapse: !this.state.collapse })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'Closed' })

  allUsersAttached = () =>
    !!this.props.facility.collector && !!this.props.facility.dsft_user && !!this.props.facility.license_owner_user

  render() {
    const {
      toggle,
      onEntering,
      onExiting,
      props: { uuid, permissions, locale, facility, match },
      state: { status, collapse }
    } = this

    return (
      // TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx`
      <div className="collapse-container">
        <div className={`collapse-header btn d-flex align-items-center flex-row clearfix ${status}`} onClick={toggle}>
          <h2 className="h4 collapse-headline">
            <span className="collapse-headline-text">{locale.contact_person_certification.headline}</span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h2>
          <div className="align-items-center d-flex ml-auto mb-0 text-right">
            {!this.allUsersAttached() && (
              <Badge
                className="text-primary-dark font-weight-normal text-right"
                color="warning"
                children="Nicht vollständig"
              />
            )}
          </div>
        </div>
        <Collapse className="collapse-content" isOpen={collapse} onEntering={onEntering} onExiting={onExiting}>
          <Row>
            <Col sm={12} className="d-flex my-2 py-0">
              <UserTable
                match={match}
                locale={locale}
                id={'contact_user_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.contact_user}
                user={facility.contact_user}
                options={facility.possible_contact_users}
                isUser={facility.contact_user}
                contact_person={facility.base_data.contact_person}
                isFacilityOwner
                hasPermission={false}
                permissions={facility.permissions}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} className="d-flex my-2 py-0">
              <UserTable
                match={match}
                locale={locale}
                id={'collector_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.collector}
                user={facility.collector}
                isUser={!!facility.collector}
                options={facility.possible_collectors}
                hasPermission={facility.permissions.change_collector}
              />
            </Col>

            <Col sm={12} md={6} className="d-flex my-2 py-0">
              <UserTable
                match={match}
                locale={locale}
                id={'secondary_collector_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.secondary_collector}
                user={facility.secondary_collector}
                isUser={!!facility.secondary_collector}
                options={facility.possible_collectors}
                hasPermission={facility.permissions.change_secondary_collector}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} className="d-flex my-2 py-0">
              <UserTable
                match={match}
                locale={locale}
                id={'dsft_user_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.dsft_user}
                user={facility.dsft_user}
                isUser={!!facility.dsft_user}
                options={facility.possible_dsft_users}
                hasPermission={facility.permissions.change_dsft_user}
              />
            </Col>
            <Col sm={12} md={6} className="d-flex my-2 py-0">
              <UserTable
                match={match}
                locale={locale}
                id={'license_owner_user_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.license_owner_user}
                user={facility.license_owner_user}
                isUser={!!facility.license_owner_user}
                options={facility.possible_license_owner_users}
                hasPermission={facility.permissions.change_license_owner_user}
              />
            </Col>
          </Row>
          <hr className="seperator" />
          <AssignedUsers facilityUuid={facility.uuid} />
        </Collapse>
      </div>
    )
  }
}

const UserTable = ({
  match,
  locale,
  user,
  headline,
  isUser,
  id,
  isFacilityOwner,
  options,
  uuid,
  hasPermission,
  contact_person,
  permissions
}) => {
  const badgeId = useId()

  return (
    <TitledBoxWithActions
      // NOTE: not ideal to define everything inline, but thats the current state of the migration
      // TODO: refactor to a separate component
      title={
        <>
          {headline || ''}
          {isUser && (
            <>
              {' '}
              <Badge id={badgeId} color="primary">
                <FontAwesomeIcon icon={faUser} />
              </Badge>
              <UncontrolledTooltip placement="right" target={CSS.escape(badgeId)}>
                Benutzer
              </UncontrolledTooltip>
            </>
          )}
        </>
      }
      // NOTE: not ideal to define everything inline, but thats the current state of the migration
      // TODO: refactor to a separate component
      actionItems={
        <>
          {isFacilityOwner && (
            <>
              {!isUser && permissions.create_contact_user && (
                <Create
                  match={match}
                  locale={locale}
                  contact_person={contact_person}
                  headline={headline}
                  user={user}
                  isUser={isUser}
                  options={options}
                  id={id}
                  uuid={uuid}
                />
              )}{' '}
              {permissions.change_contact_user && (
                <Update
                  match={match}
                  locale={locale}
                  contact_person={contact_person}
                  headline={headline}
                  user={user}
                  isUser={isUser}
                  options={options}
                  id={id}
                  uuid={uuid}
                  unlinkingAllowed
                />
              )}{' '}
              {!isUser && permissions.update_base_data && (
                <UpdateData
                  match={match}
                  locale={locale}
                  contact_person={contact_person}
                  headline={headline}
                  user={user}
                  isUser={isUser}
                  options={options}
                  id={id}
                  uuid={uuid}
                />
              )}{' '}
            </>
          )}

          {!isFacilityOwner && hasPermission && (
            <Update
              match={match}
              headline={headline}
              user={user}
              isUser={isUser}
              options={options}
              id={id}
              uuid={uuid}
            />
          )}
        </>
      }
    >
      {user?.salutation?.label || contact_person?.salutation?.label || ''}{' '}
      {user?.first_name || contact_person?.first_name || ''} {user?.last_name || contact_person?.last_name || ''}
      <br />
      <EmailLink email={user?.email || contact_person?.email || ''} />
      <br />
      <PhoneLink phone={user?.phone || contact_person?.phone || ''} />
    </TitledBoxWithActions>
  )
}

export default ContactPersonCertification
